import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { DataService } from '../data.service';
import { DateUtil } from '../utils/date.util';
import { CodError } from '../utils/date.enum';
import { MsgError } from '../utils/date.enum';
import { first } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { AttachmentService } from '../services/attachment.service';
import { AuthService } from '../auth.service';
import { User } from '../interfaces/user';
import { NgbTimepickerConfig } from '@ng-bootstrap/ng-bootstrap';
import { Location } from '@angular/common';
import swal from 'sweetalert2';
import { trigger, state, style, animate, transition } from '@angular/animations';
import * as _ from 'lodash';
import { PollSaveType } from '../utils/poll-save-type.enum';
import { PollType } from '../utils/poll-type.enum';

declare var $: any;

interface Alert {
    type: string;
    message: string;
}

const ALERTS: Alert[] = [{
    type: 'danger',
    message: 'Please, fill all the fields',
}];

@Component({
    selector: 'app-poll-creation-generic',
    templateUrl: './poll-creation-generic.component.html',
    styleUrls: ['./poll-creation-generic.component.scss'],
    animations: [
        trigger('fadeInOut', [
            state('void', style({
                opacity: 0
            })),
            transition('void <=> *', animate(1000)),
        ]),
    ]
})

export class PollCreationGenericComponent implements OnInit {

    //#region Variables
    myTime: Date = new Date();
    showMin = false;
    minDate = { year: new Date().getFullYear(), month: new Date().getMonth() + 1, day: new Date().getDate() };
    selectedVoters: any[] = [];
    selectedCandidates: any[] = [];
    countSelectedVoters = 0;
    voters!: Object;
    partner = false;
    tob = false;
    report = false;
    apartner = false;
    vacancy = 1;
    pollTitle!: string;
    polltype: Number;
    choosePollType = true;
    partnerForm!: FormGroup;
    files: any[] = [];
    reportForm!: FormGroup;
    tobForm!: FormGroup;
    submitted = false;
    bsModalRef!: BsModalRef;
    candidateLos: any;
    todayDate = new Date();
    resultsLength = 0;
    isLoadingResults = true;
    isRateLimitReached = false;
    title!: string;
    requiredFields = false;
    alerts!: Alert[];
    loader = false;
    error = false;
    success = false;
    selectedFiles: FileList | null = null;
    filePath: any[] = [];
    fileName: any[] = [];
    filePathReport: string[] = [];
    filePathReportName: string[] = [];
    user!: User;
    selectedPhoto!: File;
    photoPath: any[] = [];
    loading = false;
    loading2 = false;
    dataInvalida = false;
    dataInvalidaEnd = false;
    dataInvalidaMaior = false;
    percentDone!: number;
    uploadSuccess!: boolean;
    loaderAtt = false;
    validators = true;
    atLeastOneCandidate!: boolean;
    errors: any = {}
    loadingVoter = false;
    countVoters = 0;
    voterError = false;
    saving = false;
    startRequired = false;
    endRequired = false;
    startEarlierEnd = false;
    oneHour = false;
    msgErr!: string;
    groupError = false;
    selectedGroupItems: any[] = [];
    selectedGroups: any[] = [];
    groupSettings = {};
    pollDataGroups: any;
    countSelectedGroups = 0;
    countShowVoterTable = 0;
    countShowCandidateTable = 0;
    approver_message: string;
    approved: PollSaveType = PollSaveType.Approved;    
    //#endregion Variables

    constructor(private router: Router, private http: HttpClient, private formBuilder: FormBuilder,
        private modalService: BsModalService, private dataService: DataService, private dateUtil: DateUtil,
        private attachmentService: AttachmentService, private auth: AuthService, config: NgbTimepickerConfig, private location: Location) {
        config.spinners = false;
    }

    // convenience getter for easy access to form fields
    get f() {
        if (this.reportForm.controls.begin_date.value == 'Sat Jan 01 2000 00:00:00 GMT-0200 (Brasilia Summer Time)'
            || this.reportForm.controls.begin_date.value == null || this.reportForm.controls.begin_date.value == '') {
            this.reportForm.controls['begin_date'].setValue(null);
            this.dataInvalida = true;
        } else {
            this.dataInvalida = false;
        }

        if (this.reportForm.controls.end_date.value == 'Sat Jan 01 2000 00:00:00 GMT-0200 (Brasilia Summer Time)'
            || this.reportForm.controls.end_date.value == null || this.reportForm.controls.end_date.value == '') {
            this.reportForm.controls['end_date'].setValue(null);
            this.dataInvalidaEnd = true;
        } else {
            this.dataInvalidaEnd = false;
        }

        return this.reportForm.controls;
    }

    confirmSavedPoll() {
        this.loader = false;
        this.success = true;
        setTimeout(() => {
            this.router.navigate(['/admin']);
        }
            , 5000);
    }

    pollErrorMessage() {
        this.loader = false;
        this.error = true;
        setTimeout(() => {
            this.router.navigate(['/admin']);
        }
            , 5000);
    }

    //#region Alert
    close(alert: Alert) {
        this.alerts.splice(this.alerts.indexOf(alert), 1);
    }
    //#endregion Alert

    
    //#region Action Buttons
    back() {
        this.choosePollType = true;
        this.report = false;
        this.tob = false;
        this.partner = false;
        // ADICIONAR ALERTA AVISANDO QUE SE RETORNAR TODOS OS DADOS SERÃO APAGADOS E REALMENTE APAGAR TUDO
    }

    cancel() {
        swal.fire({
            title: 'Are you sure?',
            text: "All information will be lost.",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#d04a02',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, I am sure.'
        }).then((result: any) => {
            if (result.value) {
                this.router.navigate(['/admin']);
            }
        });
    }

    goBack() {
        window.history.back();
        this.location.back();
    }

    onSubmit(saveType: Event) {
        this.saving = true;
        this.selectedVoters = _.map(this.voters as never[], 'pwc_guid');
        let saveTypeId: number = Number((event.target as Element).id);
        this.reportForm.controls['email'].clearValidators();
        this.reportForm.controls['email'].updateValueAndValidity();
        this.reportForm.controls['selectedGroups'].clearValidators();
        this.reportForm.controls['selectedGroups'].updateValueAndValidity();

        if (saveTypeId === PollSaveType.Draft)
            this.clearValidators();
        else
        {
            if(!this.validateDates()) return;            
            if(!this.validateGroups()) return;
            if(!this.validateVoters()) return;
            if(saveTypeId === PollSaveType.Approved) this.approver_message = PollSaveType[this.approved];
        }

        this.reportForm.controls['saveType'].setValue(saveTypeId);
        this.reportForm.controls['selectedVoters'].setValue(this.selectedVoters);
        this.reportForm.controls['pollType'].setValue(this.polltype);        
        this.reportForm.value.file = this.filePath;
        this.reportForm.controls['current_user'].setValue(this.user.guid);
        this.reportForm.controls['selectedGroups'].setValue(this.selectedGroups);        
        this.reportForm.controls['files'].setValue(this.filePathReport);
        this.submitted = true;
        this.reportForm.controls['approver_message'].setValue(this.approver_message);

        if (this.reportForm.invalid) {
            // this.submitted = false;
            // this.requiredFields = true;
            this.saving = false;
            window.scroll(0, 0);
            return;
        }

        if (this.reportForm.value.begin_date.day != '' && this.reportForm.value.begin_date.day != undefined) {
            this.reportForm.value.begin_date = this.dateUtil.transformToDate(this.reportForm.value.begin_date, this.reportForm.value.begin_time)

            if (this.reportForm.value.begin_date == 'Invalid Date') {
                this.reportForm.value.begin_date = '';
            } else {
                this.reportForm.value.begin_date.toString();
                this.reportForm.controls['begin_date'].setValue(this.reportForm.value.begin_date);
            }
        } else {
            this.reportForm.controls['begin_date'].setValue('')
            this.reportForm.value.begin_date = '';
        }

        if (this.reportForm.value.end_date.day != '' && this.reportForm.value.end_date.day != undefined) {
            this.reportForm.value.end_date = this.dateUtil.transformToDate(this.reportForm.value.end_date, this.reportForm.value.end_time)

            if (this.reportForm.value.end_date == 'Invalid Date') {
                this.reportForm.value.end_date = '';
            } else {
                this.reportForm.value.end_date.toString();
                this.reportForm.controls['end_date'].setValue(this.reportForm.value.end_date);
            }
        } else {
            this.reportForm.controls['end_date'].setValue('')
            this.reportForm.value.end_date = '';
        }
        
        this.loader = true;
        console.log(this.reportForm.value);
        this.dataService.createPoll(this.reportForm.value)
            .pipe(first())
            .subscribe(
                data => swal.fire({ title: 'Success!', text: 'The poll has been saved.', icon: 'success', confirmButtonColor: '#d04a02' })
                    .then(() => {
                        this.router.navigate(['/admin']);
                    }),
                error => swal.fire({
                    title: 'Oops...',
                    html: '<p>We are sorry, an error has occurred. Please contact the system administrators.</p>' +
                        '<br><div class="alert alert-danger" role="alert"><p> Error: </p><br>' + error.error.message + '<br><br>'
                        + error.message + '</div>',
                    icon: 'error', confirmButtonColor: '#d04a02'
                })
                    .then(() => {
                        this.router.navigate(['/admin']);
                    }));
    }
    //#endregion Action Buttons


    //#region Attachment
    onFileChange(event: any) {
        this.selectedFiles = event.target.files;
    }

    onUploadReport() {
        if (this.selectedFiles && this.selectedFiles.length > 0) {
            const uploadPromises = [];
            for (let i = 0; i < this.selectedFiles.length; i++) {
                const file = this.selectedFiles[i];
                const uploadData = new FormData();
                uploadData.append("myFile", file, file.name);
                uploadData.append("fileType", "BUSINESSCASE");

                uploadPromises.push(
                    this.attachmentService.sendAttachment(uploadData).toPromise()
                        .then((data: any) => {
                            this.filePathReport.push(data.toString());
                            this.filePathReportName.push(file.name);
                        })
                        .catch((error: any) => {
                            swal.fire({
                                title: "Oops...",
                                html: `
                                    <p>We are sorry, an error has occurred. Please contact the system administrators.</p>
                                    <br>
                                    <div class="alert alert-danger" role="alert">
                                        <p> Error: </p><br>
                                        ${error.error.message}<br><br>
                                        ${error.message}
                                    </div>`,
                                icon: "error",
                                confirmButtonColor: "#d04a02",
                            });
                        })
                );
            }

            this.loader = true;
            Promise.all(uploadPromises).then(() => {
                this.selectedFiles = null;
                this.loader = false;
            });
        }
    }
    //#endregion Attachment


    //#region Clear Fields
    clearValidators(){
        this.reportForm.controls['name'].clearValidators();
        this.reportForm.controls['name'].updateValueAndValidity();

        this.reportForm.controls['description'].clearValidators();
        this.reportForm.controls['description'].updateValueAndValidity();

        this.reportForm.controls['begin_date'].clearValidators();
        this.reportForm.controls['begin_date'].updateValueAndValidity();

        this.reportForm.controls['begin_time'].clearValidators();
        this.reportForm.controls['begin_time'].updateValueAndValidity();

        this.reportForm.controls['end_date'].clearValidators();
        this.reportForm.controls['end_date'].updateValueAndValidity();

        this.reportForm.controls['end_time'].clearValidators();
        this.reportForm.controls['end_time'].updateValueAndValidity();

        this.reportForm.controls['selectedVoters'].clearValidators();
        this.reportForm.controls['selectedVoters'].updateValueAndValidity();
    }
    //#endregion Clear Fields


    //#region Groups
    onGroupDeSelectAll(items: any) {
        this.countSelectedGroups = 0;
    }

    onGroupItemDeSelect(item: any) {
        this.countSelectedGroups--;
    }

    onGroupItemSelect(item: any) {
        this.countSelectedGroups++;
        this.groupError = false;
    }

    onGroupSelectAll(items: any) {
        this.countSelectedGroups = items.length;
        this.groupError = false;
    }

    selectGroup() {        
        this.dataService.getGroups().subscribe(data => {
            this.pollDataGroups = data;
        });

        this.groupSettings = {
            singleSelection: false,
            itemsShowLimit: 5,
            enableSearchFilter: false,
            selectAllText: 'Select All',
            unSelectAllText: 'UnSelect All',
            classes: "myclass custom-class-example",
            idField: 'id',
            textField: 'groupname',
            labelKey: 'groupname',
        };
    }
    //#endregion Groups


    //#region OnInit
    ngOnInit() {
        this.buildForms();
        this.polltype = PollType.GENERIC;
        this.title = 'Generic';
        this.report = true;
        this.choosePollType = false;
        this.pollTitle = 'New Generic poll - ' +
            this.todayDate.toLocaleString('en-us', { month: 'long' }) + ' ' + this.todayDate.getFullYear();
        this.reportForm.controls['name'].setValue(this.pollTitle);
        this.user = this.auth.getUserInfo();
        this.alerts = Array.from(ALERTS);
        this.selectGroup();
    }
    //#endregion OnInit


    //#region Validations
    buildForms() {
        this.reportForm = this.formBuilder.group({
            name: ['', Validators.required],
            description: ['', Validators.required],
            begin_date: ['', Validators.required],
            begin_time: ['0', Validators.required],
            end_date: ['', Validators.required],
            end_time: ['0', Validators.required],
            email: [''],
            pollType: ['', Validators.required],
            saveType: ['', Validators.required],
            selectedVoters: ['', Validators.required],
            files: [''],
            current_user: [''],
            selectedGroups: ['', Validators.required],
            approver_message: ['']
        });
    }

    validateDates(){
        this.msgErr = '';
        this.startRequired = false;
        this.endRequired = false;
        this.startEarlierEnd = false;
        this.oneHour = false;
        let model = {
            beginDate: this.reportForm.value.begin_date, endDate: this.reportForm.value.end_date
            , beginTime: this.reportForm.value.begin_time, endTime: this.reportForm.value.end_time, codError: 0, msgError: ""
        };
        if (!this.dateUtil.validateDateTime(model)) {
            this.startRequired = model.codError == CodError.StartRequired ? true : false;
            this.endRequired = model.codError == CodError.EndRequired ? true : false;
            this.startEarlierEnd = model.codError == CodError.StartEarlierEnd ? true : false;
            this.oneHour = model.codError == CodError.OneHour ? true : false;
            if (this.startRequired) {
                this.msgErr = MsgError.StartRequired;
            }
            else if (this.endRequired) {
                this.msgErr = MsgError.EndRequired;
            }
            else if (this.startEarlierEnd) {
                this.msgErr = MsgError.StartEarlierEnd;
            }
            else if (this.oneHour) {
                this.msgErr = MsgError.OneHour;
            }

            this.saving = false;
            return false;
        }

        return true;
    }

    validateGroups(){
        if (this.selectedGroups == undefined) {
            this.groupError = true;
            this.saving = false;
            return false;
        }

        return true;
    }

    validateVoters(){
        if (this.voters == undefined) {
            this.voterError = true;
            this.saving = false;
            return false;
        }

        return true;
    }
    //#endregion Validations


    //#region Voters
    loadVoters() {
        if (this.countSelectedGroups == 0) {
            this.groupError = true;
            return
        }

        this.loadingVoter = true;
        this.dataService.getPartnersGroup(this.selectedGroupItems).subscribe(data => {
            this.loadingVoter = false;
            this.voters = data;
            this.voterError = false;
            this.countVoters = Object.keys(this.voters).length;
            $('#tabelaVoters').DataTable().clear().draw();
            $.each(data,
                function (key: any, item: { name: any; }) {
                    $("#tabelaVoters").DataTable().row.add([
                        item.name
                    ]).draw();
                });
            this.countShowVoterTable++;
        });
    }
    //#endregion Voters
}
