import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Profile } from 'src/assets/profile.enum';
import { AuthService } from '../auth.service';
import { HttpClient } from '@angular/common/http';
import { User } from '../interfaces/user';

@Component({
    selector: 'app-login',
    template: ''
})

export class LoginComponent implements OnInit {
    constructor(private authService: AuthService, private route: ActivatedRoute, private router: Router, private http: HttpClient) {
        var code = this.route.snapshot.queryParams['code'];
        //code = 'debug'; // descomentar para debug    

        if (code != null) {
            this.authService
                .login(code)
                .subscribe(() => {
                    this.redirect();
                }, () => {
                    if (code == 'debug')
                        this.debugFrontFunction();
                    else
                        this.router.navigate(['access-denied']);                    
                });
        } else if (!this.authService.isAuthenticated()) {
            this.authService.redirectToAuthentication();
        } else {
            this.redirect();
        }
    }

    private redirect(): void {
        const role = this.authService.getUserInfo().role;

        if (role == Profile.Partner || role == Profile.Sponsor)
            this.router.navigate(['/polls']);
        else if (role == Profile.Lawyer)
            this.router.navigate(['/results']);
        else if (role == Profile.Admin)
            this.router.navigate(['/admin']);
        else if (role == Profile.SuperUser)
            this.router.navigate(['/polls']);        
    }

    // adicionar login de usuario para debug
    private debugFrontFunction(): void {  
        let currentUser: User = {
            token: 'dummy_access_token',
            refreshToken: 'dummy_refresh_token',
            family_name: 'FamilyName',
            given_name: 'Talison',
            role: Profile.Admin,
            guid: 'toliveira025'
        };
        sessionStorage.setItem('currentUser', JSON.stringify(currentUser));
        this.redirect();
    }

    ngOnInit() { }
}    
