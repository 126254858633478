<div class="poll-back-padding">
    <a class="poll-link-back" (click)="goBack()"><b><</b><u>&nbsp;Voltar</u></a>
</div>
<h2>Edit poll draft&nbsp;</h2>
<p class="poll-sub-title">Fill out the form below in order to edit the poll</p>
<div *ngIf="poll_detail.saveType == 3"  class="alert alert-warning alert-dismissible fade show" role="alert">
    <div class="icon-action icon-exclamation-grey"></div> <strong>  Sponsor message:</strong> {{poll_detail.approver_message}}
</div>

<form [formGroup]="reportForm" (ngSubmit)="onSubmit($event)">
    <h3 class="form-division-heading"><span class="form-step">1</span>Basic Information</h3>
    <!-- Title -->
    <div class="form-group"><label>Title</label>
        <input type="text" placeholder="Poll title" maxlength="128" formControlName="name" [value]="poll_detail.name"  class="form-control  poll-form-fields" [ngClass]="{ 'is-invalid': submitted && f.name.errors }" />
        <div *ngIf="submitted && f.name.errors" class="invalid-feedback">
            <div *ngIf="f.name.errors.required">Title is required</div>
        </div>
    </div>
    
    <!-- Description -->
    <div class="form-group"><label>Description</label>
        <textarea placeholder="Poll description" autofocus="" [value]="poll_detail.description" formControlName="description" class="form-control  poll-form-fields"
        [ngClass]="{ 'is-invalid': submitted && f.description.errors }"></textarea>
        <div *ngIf="submitted && f.description.errors" class="invalid-feedback">
            <div *ngIf="f.description.errors.required">Description is required</div>
        </div>
    </div>

    <!-- Date-time Begin End--> 
    <div class="form-row" [ngClass]="{ 'poll-dataInvalida': startEarlierEnd || oneHour}">
        <!-- Start date -->
        <div class="col">
            <div class="form-group"><label>Start date</label>
                <br>
                <div class="poll-datetime-position">
                    <div _ngcontent-c3="" class="ngb-tp poll-datetime">
                        <div _ngcontent-c3="" class="ngb-tp-input-container ngb-tp-hour">
                            <select class="form-control" formControlName="begin_time">
                                <option>0</option><option>1</option><option>2</option><option>3</option><option>4</option>
                                <option>5</option><option>6</option><option>7</option><option>8</option><option>9</option>
                                <option>10</option><option>11</option><option>12</option><option>13</option>
                                <option>14</option><option>15</option><option>16</option><option>17</option><option>18</option>
                                <option>19</option><option>20</option><option>21</option><option>22</option><option>23</option>
                            </select>
                        </div>
                        <div _ngcontent-c3="" class="ngb-tp-spacer poll-datetime-colon">:
                        </div>
                        <div _ngcontent-c3="" class="ngb-tp-input-container ngb-tp-minute poll-datetime-minute">
                            <input _ngcontent-c3="" disabled aria-label="Minutes" class="form-control" maxlength="2" placeholder="00" type="text">
                        </div>
                    </div>
                </div>
                <div class="poll-calendar-start">
                    <ngb-datepicker [firstDayOfWeek]="0" [ngClass]="{ 'poll-dataInvalida': startRequired }" formControlName="begin_date" [minDate]="minDate"></ngb-datepicker>
                    <div *ngIf="startRequired" class="poll-message-error">{{msgErr}}</div>
                </div>
            </div>
        </div>

        <!-- End date -->
        <div class="col">
            <div class="form-group"><label>End date</label>
                <br>
                <div class="poll-datetime-position">
                    <div _ngcontent-c3="" class="ngb-tp poll-datetime">
                        <div _ngcontent-c3="" class="ngb-tp-input-container ngb-tp-hour">
                            <select class="form-control" formControlName="end_time">
                                <option>0</option><option>1</option><option>2</option><option>3</option><option>4</option>
                                <option>5</option><option>6</option><option>7</option><option>8</option><option>9</option>
                                <option>10</option><option>11</option><option>12</option><option>13</option>
                                <option>14</option><option>15</option><option>16</option><option>17</option><option>18</option>
                                <option>19</option><option>20</option><option>21</option><option>22</option><option>23</option>
                            </select>
                        </div>
                        <div _ngcontent-c3="" class="ngb-tp-spacer poll-datetime-colon">:
                        </div>
                        <div _ngcontent-c3="" class="ngb-tp-input-container ngb-tp-minute poll-datetime-minute">
                            <input _ngcontent-c3="" disabled aria-label="Minutes" class="form-control" maxlength="2" placeholder="00" type="text">
                        </div>
                    </div>
                </div>
                <div class="poll-calendar-end">
                    <ngb-datepicker [firstDayOfWeek]="0" formControlName="end_date" [minDate]="minDate" [ngClass]="{ 'poll-dataInvalida': endRequired }"></ngb-datepicker>
                    <div *ngIf="endRequired" class="poll-message-error">{{msgErr}}</div>
                </div>
            </div>
        </div>
    </div>

    <div *ngIf="startEarlierEnd || oneHour" class="poll-message-error">{{msgErr}}</div>

    <!-- Email -->
    <div class="form-group"><label>E-mail message</label>
        <textarea placeholder="Write down the email message for the voters" autofocus="" formControlName="email" class="form-control  poll-form-fields"></textarea>
    </div>
    <br>

    <!-- Group -->
    <div class="form-group">
        <h3 class="form-division-heading"><span class="form-step">2</span>Select Groups</h3>
    </div>
    <div [ngClass]="{ 'poll-groupError poll-groupError-dropdown': groupError }">
        <ng-multiselect-dropdown
            [placeholder]="'Select Groups'"
            [settings]="groupSettings"
            [data]="pollDataGroups"
            [(ngModel)]="selectedGroupItems"        
            (onSelect)="onGroupItemSelect($event)"
            (onDeSelect)="onGroupItemDeSelect($event)"
            (onSelectAll)="onGroupSelectAll($event)"
            (onDeSelectAll)="onGroupDeSelectAll($event)"
            formControlName="selectedGroups">
        </ng-multiselect-dropdown>
        <div *ngIf="groupError" class="poll-message-error">
            Select Groups
        </div>
    </div>
    <br>

    <!-- Voters -->
    <div class="form-group poll-title-section">
        <h3 class="form-division-heading"><span class="form-step">3</span>Voters Information</h3>
        <span class="badge poll-total-count">{{countVoters}} voters selected</span>
    </div>
    <div class="row" >
        <div [ngClass]="{ 'poll-voterError': voterError }">
            <a class="btn poll-btn" (click)="loadVoters()">Load Voters</a>
            <div *ngIf="voterError" class="poll-message-error">
                Voters are required
            </div>
        </div>

        <div class="col-sm-12" *ngIf="loadingVoter">
            <div class="spinner">
                <div class="spinnerInner">
                    <img src="..\assets\img\loader_old.gif">
                </div>
            </div>
        </div>
        <div [hidden]="!countShowVoterTable" class="col-sm-12">
            <table id="tabelaVoters" datatable class="table  table-bordered table-hover">
                <thead>
                    <tr>
                        <th>Partner Name</th>
                    </tr>
                </thead>
                <tbody class="poll-content-list">
                </tbody>
            </table>
        </div>
    </div>
    <br>

    <!-- Attachment -->
    <h3 class="form-division-heading"><span class="form-step">4</span>Attachment</h3>
    <div class="form-group row">
        <div class="col-md-10 attachment-field">
            <div *ngIf="link.length != 0">
                <div>
                    Download the attached document
                    <br>
                    <br>
                    <div>
                        <button type="button" class="btn btn-download-attachment" (click)="getDownload(fName, link)" >
                            Download <div class="icon-action icon-download-grey"></div>
                        </button>
                    </div>
                </div>
                Add attachments
                <br>
                <br>
            </div>
            <div *ngIf="loadingAttachment">
                <img src="../assets/img/img_loader.gif">
            </div>
            <div class="row">
                <span class="btn poll-btn-attachment-browser" (click)="fileInput.click()">
                    Browse file <div class="icon-action icon-search-white"></div> <input class="poll-btn-display" type="file" (change)="onFileChanged($event)" #fileInput multiple>
                </span>
                <span class="btn poll-btn-attachment-upload" (click)="onUploadReport()">
                    Upload <div class="icon-action icon-upload-white"></div>
                </span>
            </div>            
            <br>
            <div class="row files-name">
                <p *ngIf="filePathReportName.length > 0">
                    Files name:
                    <li class="poll-files-name" *ngFor="let file of filePathReportName">{{file}}</li>                
                </p>
            </div>
        </div>
    </div>
    

    <!-- Action Button -->
    <a class="float" id="menu-share">
        <div class="poll-icon-send icon-plus-white"></div>
    </a>
    <ul *ngIf="!saving">
        <li placement="left" ngbTooltip="Approve" *ngIf="user.role === 0 || user.role === 4">
            <a class="poll-btn-send-approved" role="button" (click)="onSubmit($event)" name="Approved" id="2">                
                <div id="2" class="poll-icon-send icon-checkmark-white"></div>
            </a>
        </li>
        <li placement="left" ngbTooltip="Send for Approval" *ngIf="user.role === 1 || user.role === 4">
            <a class="poll-btn-send-pending-approval" role="button" (click)="onSubmit($event)" name="PendingApproval" id="1">
                <div id="1" class="poll-icon-send-air-plane icon-paper-airplane-white"></div>
            </a>
        </li>
        <li placement="left" ngbTooltip="Save as Draft">
            <a class="poll-btn-send-draft" role="button"(click)="onSubmit($event)" name="Draft" id="0">
                <div id="0" class="poll-icon-send icon-save-white"></div>
            </a>
        </li>
        <li placement="left" ngbTooltip="Cancel">
            <a class="poll-btn-send-cancel" (click)="cancel()">
                <div class="poll-icon-send icon-close-white"></div>
            </a>
        </li>
    </ul>
</form>

<!-- Loader/ Alert-->
<div *ngIf="loader" class='overlay'>
    <div class="spinner">
        <div class="spinnerInner">
            <img src="..\assets\img\loader_old.gif">
        </div>
    </div>
</div>
<div *ngIf="error" class='overlay'>
    <div class="alert alert-danger alert-dismissible fade show"><span><i class="icon-action icon-checkmark-white"></i></span>
        We are sorry, an error has occurred. Please contact the system administrators.
        <button type="button" class="close" data-dismiss="alert" aria-label="Close">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
</div>
<div *ngIf="success" class='overlay'>
    <div class="alert alert-success"><span><i class="icon-action icon-checkmark-white"></i></span>
        The draft has been saved</div>
</div>

