<div class="poll-back-padding">
    <a class="poll-link-back" (click)="goBack()"><b><</b><u>&nbsp;Voltar</u></a>
</div>
<h3>Poll Details</h3>
<h4 class="name-poll">{{poll_detail.name}}<br></h4>

<!-- Details -->
<div class="row">
    <div class="col">
        <div class="cardPadding border status-poll">
            <h5>Poll status</h5>
            <p class="badge" [ngClass]="{
                            'badge-draft': poll_detail.saveType==0,
                            'badge-pending': poll_detail.saveType==1,
                            'badge-approved': poll_detail.saveType==2,
                            'badge-action': poll_detail.saveType==3,
                            'badge-active': poll_detail.saveType==4,
                            'badge-closed': poll_detail.saveType==5,
                            'badge-accounted': poll_detail.saveType==6}">
                            {{poll_detail.saveType | PollstatusPipe}}
            </p>
            <p class="label-poll label-status-type"></p>
            <h5>Poll Type</h5>
            <p class="badge badge-dark">{{poll_detail.pollType | PollTypePipe}}</p>            
            <p class="label-poll label-status-type"></p>
            <h5>Created by</h5>
            <p class="label-poll">{{poll_detail.created_by_pwc_guid}}</p>
            <h5>Created Date</h5>
            <p>{{poll_detail.created_at | dateTimeFormatFilter : "MMM DD, YYYY HH:mm"}}</p>
        </div>
    </div>
    <div class="col">
        <div class="cardPadding border status-poll">
            <h5>Poll description</h5>
            <p class="label-poll">{{poll_detail.description}}</p>
            <h5>Poll Begin Date</h5>
            <p class="label-poll">{{poll_detail.begin_date | dateTimeFormatFilter : "MMM DD, YYYY HH:mm"}}</p>
            <h5>Poll End Date</h5>
            <p [ngClass]="{ 'label-poll': poll_detail.pollType <= 1 ||  link.length > 0}">
                {{poll_detail.end_date | dateTimeFormatFilter : "MMM DD, YYYY HH:mm"}}</p>
            <h5 *ngIf="poll_detail.pollType <= 1">Poll Vacancies</h5>
            <p *ngIf="poll_detail.pollType <= 1">{{poll_detail.vacancy}}</p>
            <div *ngIf="link.length > 0">
                <h5>Archives</h5>
                <button type="button" class="btn btn-donwload-archives" (click)="getDownload(fName, link)">
                    Download files&nbsp;
                    <div class="icon-action icon-download-grey"></div>
                </button>
            </div>
        </div>
    </div>
</div>

<div *ngIf="failed" class='overlay'>
    <div class="alert alert-danger alert-dismissible fade show"><span><i class="icon-action icon-checkmark-white"></i></span>
        The message has been failed
        <button type="button" class="close" data-dismiss="alert" aria-label="Close">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
</div>
<br>

<!-- Nav Tabs -->
<!-- class="nav-tabs" -->
<ul ngbNav #nav="ngbNav" >
    <!-- Voters -->
    <li [ngbNavItem]="1">
        <a ngbNavLink>
            <span class="customTab tab-title">Voters</span>
        </a>
        <ng-template ngbNavContent>
            <div class="row" *ngIf="user.role <= 1 || user.role === 4">
                <div class="col">
                    <div class="card-body table-head-details">
                        <div class="table-responsive">
                            <table class="voters-table-head">
                                <thead>
                                    <tr>
                                        <th>
                                            <h5 class="card-title poll-content-list">Voters List</h5>
                                        </th>
                                        <th *ngIf="poll_detail.saveType >= 4">
                                            <h6>Submitted votes: {{votedCount}} &nbsp;&nbsp; Pending votes: {{notVotedCount}}</h6>
                                        </th>
                                        <th class="label-send-vote">
                                            <a [ngClass]="{ 'disabled': poll_detail.saveType != 4 }"
                                                class="btn-action btn btn-md mr-2 label-send-vote-position" role="button"
                                                (click)="openModal(content, voterN)" placement="top" ngbTooltip="Send Email">
                                                Send to all <div class="icon-email"></div>
                                            </a>
                                        </th>
                                    </tr>
                                </thead>
                            </table>
                        </div>
                        <hr class="row-head-margin">
                        <div class="table-responsive">
                            <table class="table table-bordered table-hover">
                                <thead>
                                    <tr>
                                        <th class="content-list-voter-name">Name</th>
                                        <th *ngIf="poll_detail.saveType >= '4'" class="content-list-voter-align">Status</th>
                                        <th class="content-list-voter-align">Action</th>
                                        <th *ngIf="poll_detail.saveType >= '4'">Last Send</th>
                                    </tr>
                                </thead>
                                <tbody class="color-title">
                                    <tr *ngFor="let voter of poll_detail.voters">
                                        <td>{{voter.name}}</td>
                                        <td *ngIf="poll_detail.saveType >= '4'" class="content-list-voter-align">
                                            <span class="badge" [ngClass]="{
                                                'badge-danger': poll_detail.saveType >= 5 && voter.voted == false,
                                                'badge-warning': voter.voted == false,
                                                'badge-success': voter.voted == true
                                                }">
                                                {{voter.voted ? 'voted' : poll_detail.saveType >= 5 ? 'not voted' : 'pending'}}
                                            </span>
                                        </td>
                                        <td class="content-list-voter-align">
                                            <div role="group" class="btn-group" *ngIf="voter.voted == false">
                                                <a [ngClass]="{ 'disabled': poll_detail.saveType != '4' }" class="btn-action btn btn-md mr-2" 
                                                    role="button" (click)="openModal(content, voter)" placement="top" ngbTooltip="Send Email">
                                                    <div class="icon-email"></div>
                                                    <!-- <i class="Appkit4-icon icon-email-outline"></i> -->
                                                </a>
                                            </div>
                                        </td>
                                        <td *ngIf="poll_detail.saveType >= '4'">
                                            <div *ngIf="poll_detail.saveType >= '4'" class="text-muted">
                                                {{voter.last_sent | dateTimeFormatFilter : "MMM DD, YYYY HH:mm"}}
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </ng-template>
    </li>

    <!-- Candidates -->
    <li [ngbNavItem]="2" *ngIf="poll_detail.pollType < 4">
        <a ngbNavLink><span class="customTab tab-title">Candidates</span></a>
        <ng-template ngbNavContent>
            <div class="row" *ngIf="user.role <= 1 || user.role === 4">
                <div class="col">
                    <div class="card-body table-head-details">
                        <h5 class="card-title color-title">Candidate List</h5>
                        <div class="table-responsive">
                            <table *ngIf="poll_detail.pollType < 2 || poll_detail.pollType > 3"
                                class="table table-bordered table-hover">
                                <thead>
                                    <tr>
                                        <th class="content-list-candidate">Name</th>
                                    </tr>
                                </thead>
                                <tbody class="color-title">
                                    <tr *ngFor="let candidate of poll_detail.candidates">
                                        <td>{{candidate.candidatename}}</td>
                                    </tr>
                                </tbody>
                            </table>
                            <table *ngIf="poll_detail.pollType >= 2 && poll_detail.pollType <= 3"
                                class="table table-bordered table-hover">
                                <thead>
                                    <tr>
                                        <th class="content-list-candidate">Photo</th>
                                        <th class="content-list-candidate">Name</th>
                                        <th class="content-list-candidate">LOS</th>
                                        <th class="content-list-candidate">Business Case</th>
                                    </tr>
                                </thead>
                                <tbody class="color-title">
                                    <tr *ngFor="let candidate of poll_detail.candidates; let i=index ">
                                        <td *ngIf="!nullPhoto[i]">
                                            <img class="img-thumbnail" width="75px" height="75px" src="{{photoBytes[i]}}">
                                        </td>
                                        <td *ngIf="nullPhoto[i]"><img class="img-thumbnail" width="75px" height="75px" src="../../assets/img/user-default.png"></td>
                                        <td>{{candidate.candidatename}}</td>
                                        <td>{{candidate.candidateLos}}</td>
                                        <td>
                                            <button type="button" *ngIf="!nullFile[i]" class="btn btn-light btn-download-candidate btn-download-candidate__yes-attachments"
                                                (click)="getDownloadBusinessCase(candidate.attachments[1].name, candidate.attachments[1].link)">
                                                Download Business Case <div class="icon-action icon-download-orange"></div>
                                            </button>
                                            <button class="btn btn-light btn-download-candidate btn-download-candidate__no-attachments"
                                                type="button" *ngIf="nullFile[i]"  disabled="true"> 
                                                No Business Case Attached <div class="icon-action icon-download-light-grey"></div>
                                            </button>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </ng-template>
    </li>
</ul>
<div [ngbNavOutlet]="nav" class="mt-2"></div>

<!-- Email/ Modal-->
<ng-template #content let-modal>
    <div class="modal-header">
        <h4 class="modal-title">Send E-mail</h4>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <form #emailForm="ngForm" (ngSubmit)="sendEmail(emailForm)">
            <div class="form-group">
                <label for="message">Mensagem</label>
                <textarea id="message" name="message" class="form-control" [(ngModel)]="emailContent.message_content" required></textarea>
            </div>
            <button type="submit" class="btn btn-modal">Enviar</button>
        </form>
        <div *ngIf="voterSelected && voterSelected.length > 0">
            <p>Selected Voters:</p>
            <ul>
                <li class="selected-voters" *ngFor="let voter of voterSelected">{{ voter.name }}</li>
            </ul>
        </div>
    </div>
</ng-template>

<!-- Loader/ Alert-->
<div *ngIf="loader" class='overlay'>
    <div class="spinner">
        <div class="spinnerInner">
            <img src="..\assets\img\loader_old.gif">
        </div>
    </div>
</div>
