import { Component, OnInit, OnDestroy } from '@angular/core';
import { AuthService } from '../auth.service';
import { User } from '../interfaces/user';
import { DataService } from '../data.service';
import { BookingService } from '../services/sidebar.service';
import { BnNgIdleService } from 'bn-ng-idle';
import { HttpClient } from '@angular/common/http';
import Swal from 'sweetalert2';
import { environment } from '../../environments/environment';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: [
    "../../assets/bootstrap/css/bootstrap.min.css",
    "../../assets/fonts/fontawesome-all.min.css",
    "../../assets/fonts/font-awesome.min.css",
    "../../assets/css/sidebar-2.css",
    "../../assets/css/Steps-Progressbar.css",
    "../../assets/css/styles.css",
    "../../assets/css/dual-listbox.css",
    "../../assets/css/pace-theme-atom.css",
    './layout.component.scss',
  ]
})
export class LayoutComponent implements OnInit, OnDestroy {
  public user: User;
  polls: object;
  count = 0;
  ticketCount: number;
  initials: any;
  textFooter: string;

  constructor(
    private authService: AuthService,
    private data: DataService,
    private bookingService: BookingService,
    private http: HttpClient,
    private bnIdle: BnNgIdleService
  ) { }

  ngOnInit() {    
    this.textFooter = `© ${new Date().getFullYear()} PwC. Pwc refers to the PwC network and/or one or more of this member 
      firms, each of which is a separate legal entity. Please see www.pwc.com/structure for furthers details.`

    // Inicie o watcher de inatividade    
    this.bnIdle.startWatching(17700).subscribe((res: boolean) => {
      if (res) {
        this.showInactivityPopup();
      }
    });

    if (!this.authService.isAuthenticated()) {
      this.authService.logout();
    } else {
      this.user = this.authService.getUserInfo();
      this.getInitials(this.user.given_name);
      this.getPolls();
    }
  }

  ngOnDestroy() {
    this.bnIdle.stopTimer();
  }

  showInactivityPopup() {  
    let timerInterval: number;  
    const logoutTime = 60 * 1000; // 1 minuto em milissegundos        
    const initialTime = logoutTime / 1000; // tempo inicial em segundos        
  
    Swal.fire({  
        title: 'Do you want to stay logged in?',  
        html: `Your session will expire in <strong>${initialTime}</strong> seconds.<br/><br/>`,  
        timer: logoutTime,  
        timerProgressBar: true,  
        showCancelButton: true,  
        confirmButtonText: 'Continue',  
        cancelButtonText: 'Logout',  
        didOpen: () => {  
            const content = Swal.getHtmlContainer();  
            if (content) {  
                const strongEl = content.querySelector('strong');  
                if (strongEl) strongEl.textContent = initialTime.toString();  
  
                timerInterval = window.setInterval(() => {  
                    if (strongEl) {  
                        strongEl.textContent = (Swal.getTimerLeft() / 1000).toFixed(0);  
                    }  
                }, 1000);  
  
                const confirmButton = Swal.getConfirmButton();  
                const cancelButton = Swal.getCancelButton();  
                if (confirmButton) {  
                    confirmButton.style.backgroundColor = '#d04a02';  
                    confirmButton.style.color = 'white';  
                    confirmButton.style.borderRadius = '5px';  
                    confirmButton.style.padding = '10px 20px';  
                    confirmButton.style.fontSize = '16px';  
                    confirmButton.style.border = 'none';  
                    confirmButton.style.cursor = 'pointer';  
                }  
                if (cancelButton) {  
                    cancelButton.style.backgroundColor = '#6c757d';  
                    cancelButton.style.color = 'white';  
                    cancelButton.style.borderRadius = '5px';  
                    cancelButton.style.padding = '10px 20px';  
                    cancelButton.style.fontSize = '16px';  
                    cancelButton.style.border = 'none';  
                    cancelButton.style.cursor = 'pointer';  
                }  
            }  
        },  
        willClose: () => {  
            clearInterval(timerInterval);  
        }  
    }).then((result) => {  
        if (result.isConfirmed) {  
            console.log('Continuando sessão...');  
            this.bnIdle.resetTimer();  
            console.log('Token de atualização antes da renovação:', this.authService.getSession()?.refreshToken);  
            this.authService.refreshToken().subscribe(  
                (newToken) => {  
                    if (newToken) {  
                        console.log('Token renovado com sucesso: ', newToken);  
                        this.bnIdle.resetTimer(); // reiniciar o timer após renovação  
                    } else {  
                        console.error('Falha ao renovar o token.');  
                        this.logoff();  
                    }  
                },  
                (error) => {  
                    console.error('Erro ao renovar token: ', error);  
                    this.logoff();  
                }  
            );  
        } else {  
            this.logoff();  
        }  
    });  
}  

  getInitials(name: string) {
    this.initials = this.http.get('https://ui-avatars.com/api/', { params: { name: name } });
  }

  getPolls() {
    this.data.getPollsByPartnerSidebar(this.user.guid).subscribe(data => {
      this.count = +data.valueOf();
    });
  }

  public toggleNavbar() {
    const sidebarElement = document.getElementById("sidebar");
    if (sidebarElement) {
      sidebarElement.className = sidebarElement.className === 'active' ? '' : 'active';
    }
  }

  public logoff() {
    console.log('Logging out and clearing session.');
    this.authService.logout();
  }
}  
