import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PersonService } from '../services/person.service';
import { GroupService } from '../services/group.service';
import swal from 'sweetalert2';
import { Profile } from '../../assets/profile.enum';

@Component({
    selector: 'app-person-item',
    templateUrl: './person-item.component.html',
    styleUrls: ['./person-item.component.scss']
})
export class PersonItemComponent implements OnInit {
    
    //#region Variables
    public item: any = {};
    public groups: any = {};
    public profiles: any = [];
    public invalidFields: any = {}; // Campo para rastrear campos inválidos
    loader = false;
    //#endregion Variables

    constructor(private personService: PersonService, private groupService: GroupService, private route: ActivatedRoute, private router: Router) {
        this.profiles = this.personService.getProfiles().filter((profile) => profile.id != Profile.SuperUser);
        this.route
            .params
            .subscribe(p => {
                if (p.id) {
                    this.personService.get(p.id).subscribe(person => {
                            this.item = person;
                        });
                }
            });
            this.groupService.getActive().subscribe(data => {
                    this.groups = data;
                });
    }

    //#region Button Actions
    cancel() {
        swal.fire({
            title: 'Are you sure?',
            text: "All information will be lost.",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#d04a02',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, I am sure.'
        }).then((result: any) => {
            if (result.value) {
                this.router.navigate(['/settings']);
            }
        });
    }

    public saveOrUpdate(): void {  
        if (!this.isFormValid())
            return; // Se o formulário for inválido, não salva
        
        this.item.group_id = (<HTMLSelectElement>document.getElementById('group')).value;
        this.item.current_user = this.groupService.user.guid;
        this.loader = true;
    
        if (this.item.id) {
            // Se o usuário tem um ID, então ele é um usuário existente
            this.personService.update(this.item).subscribe(
                (data: any) => this.handleSuccess(),
                (error: any) => this.handleError(error)
            );
        } else {
            // Se o usuário não tem um ID, então ele é um novo usuário
            this.personService.save(this.item).subscribe(
                (data: any) => this.handleSuccess(),
                (error: any) => this.handleError(error)
            );
        }
    }
    //#endregion Button Actions


    //#region Message
    private handleSuccess() {
        swal.fire({title: '', text: 'Successfully saved.', icon: 'success', confirmButtonColor: '#d04a02'})
            .then(() => {
                this.router.navigate(['/settings']);
            });
    }
    
    private handleError(error: any) {
        swal.fire({
            title: 'Oops...',
            html: '<p>We are sorry, an error has occurred. Please contact the system administrators.</p>' +
            '<br><div class="alert alert-danger" role="alert"><p> Error: </p><br>' + error.error.message + '<br><br> </div>',
            icon: 'error', confirmButtonColor: '#d04a02'
        })
        .then(() => {
            this.loader = false;
        });
    }
    //#endregion Message


    //#region OnInit
    ngOnInit() {
    }
    //#endregion OnInit


    //#region ValidateFields
    public isFieldInvalid(field: string): boolean {
        return this.invalidFields[field];
    }

    private isFormValid(): boolean {
        this.invalidFields = {}; // Limpar campos inválidos

        // Verificação de campos obrigatórios
        if (!this.item.pwc_guid) {
            this.invalidFields['pwc_guid'] = true;
        }
        if (this.item.profile === undefined) {
            this.invalidFields['profile'] = true;
        }
        if (!this.item.name) {
            this.invalidFields['name'] = true;
        }
        if (!this.item.email) {
            this.invalidFields['email'] = true;
        }
        if (!this.item.group_id) {
            this.invalidFields['group_id'] = true;
        }

        // Se algum campo estiver inválido, retorna false
        return Object.keys(this.invalidFields).length === 0;
    }
    //#endregion ValidateFields
}
