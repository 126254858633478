<h3 class="result-titles">Results</h3>
<h5 class="result-titles">Pending verification</h5>

<!-- Poll Closed -->
<div class="card-columns-2 cards-results">
    <div class="card" *ngFor="let poll of polls"><span class="header-line"></span>
        <div class="card-header card-header-content">
            <p class="float-right card-header-label">End date: {{poll.end_date | dateTimeFormatFilter : "MMM DD, YYYY HH:mm"}}</p>
            <span class="badge" *ngIf="poll.saveType" [ngClass]="{'badge-closed': poll.saveType==5}">
                {{poll.saveType | PollstatusPipe}}
            </span>
        </div>
        <div class="card-body"> 
            <h6 class="text-muted card-subtitle mb-2" >{{poll.pollType | PollTypePipe}}</h6> 
            <h5 class="titleCut card-title card-poll-name">{{poll.name}}</h5>
            <p class="textCut text-left card-text card-poll-description">{{poll.description}}</p>
        </div>
        <div class="card-footer card-footer-height">
            <div class="btn-group d-lg-flex justify-content-lg-center" role="group">
                <a class="btn btn-success btn-result" role="button" routerLink="../results/{{poll.id}}">
                    Generate Results&nbsp;<div class="icon-action icon-share-box-white"></div>
                </a>
            </div>
        </div>
    </div>
</div>

<!-- Poll Accounted -->
<h5 class="accounted-title">Accounted polls</h5>
<div  *ngIf="accountedPolls" class="table-responsive">
    <table datatable class="table table-bordered table-hover" >
        <thead>
            <tr >
                <th class="result-table-head-title">Title</th>
                <th class="result-table-head-date">Begin Date</th>
                <th class="result-table-head-date">End Date</th>
                <th class="result-table-head-status">Status</th>
                <th class="result-table-head-action">Actions</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor ="let poll of accountedPolls ">
                <td>{{poll.name}}</td>
                <td>{{poll.begin_date | dateTimeFormatFilter : "MMM DD, YYYY HH:mm"}}</td>
                <td>{{poll.end_date | dateTimeFormatFilter : "MMM DD, YYYY HH:mm"}}</td>
                <td>
                    <span class="badge" *ngIf="poll.saveType" [ngClass]="{'badge-accounted': poll.saveType==6}">
                        {{poll.saveType | PollstatusPipe}}
                    </span>
                </td>
                <td><a class="btn-action btn btn-sm" role="button" routerLink="../results/{{poll.id}}" 
                        placement="top" ngbTooltip="Check Results"><div class="icon-action icon-see-grey"></div>
                    </a>
                </td>
            </tr>
        </tbody>
    </table>
</div>
