<link rel="stylesheet" href="https://appkitcdn.pwc.com/appkit3/cdn/release/3.5.0/vendor.min.css">
<link rel="stylesheet" href="https://appkitcdn.pwc.com/appkit3/cdn/cdn/release/3.5.0/appkit.min.css">
<script src="https://appkitcdn.pwc.com/appkit3/cdn/cdn/release/3.5.0/vendor.min.js"></script>
<script src="https://appkitcdn.pwc.com/appkit3/cdn/release/3.5.0/appkit.min.js"></script>

<div class="wrapper">
    <!-- Header  -->
    <header class="topbar">
        <div class="topbar-content">
            <div class="img-logo"></div>
            <p class="app-name">Voting</p>

            <div class="btn-sign-out">
                <button type="button" id="logoff" class="btn btn-sm" (click)="logoff()">
                    <div class="icon-power-grey"></div> Sign out
                </button>
            </div>
            <div class="icon-user">
                <img src="https://ui-avatars.com/api/?size=40&background=eb8c00&color=ffffff&rounded=true&name={{ user.given_name }}">
                <div class="user-name"><i>{{ user.given_name }}</i></div>
            </div> 
        </div>
    </header>    

    <!-- Sidebar  -->
    <div class="content-sidebar">
        <nav id="sidebar" class="active">
            <ul class="list-unstyled components">
                <li *ngIf="(user.role <= 1 || user.role == 4)" [routerLinkActive]="['active']">
                    <a class="side-icon" routerLink="/create-poll">                    
                        <div class="icons-sidebar icon-plus-grey new-poll-active"></div> 
                        New Poll
                    </a>
                </li>

                <li [routerLinkActive]="['active']">
                    <a class="side-icon" *ngIf="user.role == 0 || user.role == 2 || user.role == 4" routerLink="/polls">
                        <span class="badge badge-light count-poll">{{ count }}</span>
                        <i class="icons-sidebar icon-4quadrants-grey my-polls-active"></i>
                        My Polls
                    </a>
                </li>

                <li [routerLinkActive]="['active']">
                    <a class="side-icon" *ngIf='(user.role <= 1 || user.role == 4)' routerLink="/admin">
                        <div class="icons-sidebar icon-dashboard-grey dashboard-active"></div> 
                        Workspace
                    </a>
                </li>
                <li [routerLinkActive]="['active']">
                    <a class="side-icon" *ngIf='(user.role <= 1 || user.role == 4)' routerLink="/history">
                        <div class="icons-sidebar icon-history-grey history-active"></div> 
                        History
                    </a>
                </li>
                <li [routerLinkActive]="['active']">
                    <a class="side-icon" *ngIf='user.role == 3 || user.role == 4' routerLink="/results">
                        <div class="icons-sidebar icon-pie-chart-grey pie-chart-active"></div> 
                        Results
                    </a>
                </li>
                <li *ngIf="(user.role <= 1 || user.role == 4)" [routerLinkActive]="['active']">
                    <a class="side-icon" routerLink="/settings">
                        <div class="icons-sidebar icon-settings-grey settings-active"></div>
                        Settings
                    </a>
                </li>
                <li [routerLinkActive]="['active']">
                    <a class="side-icon" routerLink="/about">
                        <div class="icons-sidebar icon-information-grey information-active"></div>
                        About
                    </a>
                </li>
            </ul>
        </nav>
    </div>

    <!-- Page Content -->
    <div id="content" class="content-page">
        <nav class="navbar navbar-expand-lg navbar-light bg-white">
            <div class="container-fluid">
                <button type="button" id="sidebarCollapse" class="btn btn-danger btn-hidden-menu"
                    (click)="toggleNavbar()">
                    <div class="icon-action icon-menu-white"></div>
                </button>
            </div>
        </nav>
        <main class="content-page">
            <router-outlet></router-outlet>
        </main>
    </div>

    <div class="wrapper">
        <div id="inactivity-popup-container"></div>
    </div>

    <footer>
        <p class="textFooter">{{textFooter}}</p>
    </footer>
</div>