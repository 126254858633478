import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AccessDeniedComponent } from './access-denied/access-denied.component';
import { AdminDashboardComponent } from './admin-dashboard/admin-dashboard.component';
import { PersonListComponent } from './admin-person/person-list.component';
import { LayoutComponent } from './layout/layout.component';
import { LoginComponent } from './login/login.component';
import { PartnerPollsComponent } from './partner-polls/partner-polls.component';
import { PollCreationComponent } from './poll-creation/poll-creation.component';
import { PollDetailsComponent } from './poll-details/poll-details.component';
import { PollComponent } from './poll/poll.component';
import { AuthGuard } from './services/auth.guard';
import { RoleGuardService } from './RoleGuard.service';
import { PersonItemComponent } from './admin-person/person-item.component';
import { GroupItemComponent } from './admin-person/group-item.component';
import { ApprovePollComponent } from './approve-poll/approve-poll.component';
import { ResultPollComponent } from './result-poll/result-poll.component';
import { ResultDashboardComponent } from './result-dashboard/result-dashboard.component';
import { AboutComponent } from './about/about.component';
import { AdminHistoryComponent } from './admin-history/admin-history.component';
import { PollCreationBoardComponent } from './poll-creation-board/poll-creation-board.component';
import { PollCreationGenericComponent } from './poll-creation-generic/poll-creation-generic.component';
import { PollCreationPartnerComponent } from './poll-creation-partner/poll-creation-partner.component';
import { EditPollPartnerComponent } from './edit-poll-partner/edit-poll-partner.component';
import { EditPollBoardComponent } from './edit-poll-board/edit-poll-board.component';
import { EditPollGenericComponent } from './edit-poll-generic/edit-poll-generic.component';

const routes: Routes = [
    { path: '', component: LoginComponent },
    { path: 'access-denied', component: AccessDeniedComponent },
    {
        path: '',
        component: LayoutComponent,
        canActivate: [AuthGuard],
        children: [
            {
                path: 'home',
                component: AdminDashboardComponent,
                canActivate: [RoleGuardService],
                data: {
                    expectedRole: ['admin', 'sponsor', 'super'],
                    expectedDevice: ['S', 'T', 'D']
                }
            },
            {
                path: 'polls', // My Polls  (habilitar mobile)  
                component: PartnerPollsComponent,
                canActivate: [RoleGuardService],
                data: {
                    expectedRole: ['partner', 'sponsor', 'super'],
                    expectedDevice: ['S', 'T', 'D']
                }
            },
            {
                path: 'poll/:id', //votação  
                component: PollComponent,
                canActivate: [RoleGuardService],
                data: {
                    expectedRole: ['partner', 'sponsor', 'super'],
                    expectedDevice: ['S', 'T', 'D']
                }
            },
            {
                path: 'poll-details/:id', //PASSAR :ID AQUI  
                component: PollDetailsComponent,
                canActivate: [RoleGuardService],
                data: {
                    expectedRole: ['admin', 'sponsor', 'super'],
                    //expectedDevice: ['S', 'T', 'D']  
                    expectedDevice: ['D']
                }
            },
            {
                path: 'admin', // Workspace  (desabilitar mobile)  
                component: AdminDashboardComponent,
                canActivate: [RoleGuardService],
                data: {
                    expectedRole: ['admin', 'sponsor', 'super'],
                    expectedDevice: ['D']
                }
            },
            {
                path: 'history', // History  (desabilitar mobile)  
                component: AdminHistoryComponent,
                canActivate: [RoleGuardService],
                data: {
                    expectedRole: ['admin', 'sponsor', 'super'],
                    expectedDevice: ['D']
                }
            },
            {
                path: 'settings', // Settings  (desabilitar mobile)  
                component: PersonListComponent,
                canActivate: [RoleGuardService],
                data: {
                    expectedRole: ['admin', 'sponsor', 'super'],
                    expectedDevice: ['D']
                }
            },
            {
                path: 'admin/person',
                component: PersonItemComponent,
                canActivate: [RoleGuardService],
                data: {
                    expectedRole: ['admin', 'sponsor', 'super'],
                    //expectedDevice: ['S', 'T', 'D']  
                    expectedDevice: ['D']
                }
            },
            {
                path: 'admin/person/:id',
                component: PersonItemComponent,
                canActivate: [RoleGuardService],
                data: {
                    expectedRole: ['admin', 'sponsor', 'super'],
                    //expectedDevice: ['S', 'T', 'D']  
                    expectedDevice: ['D']
                }
            },
            {
                path: 'admin/group',
                component: GroupItemComponent,
                canActivate: [RoleGuardService],
                data: {
                    expectedRole: ['admin', 'sponsor', 'super'],
                    //expectedDevice: ['S', 'T', 'D']  
                    expectedDevice: ['D']
                }
            },
            {
                path: 'admin/group/:id',
                component: GroupItemComponent,
                canActivate: [RoleGuardService],
                data: {
                    expectedRole: ['admin', 'sponsor', 'super'],
                    //expectedDevice: ['S', 'T', 'D']  
                    expectedDevice: ['D']
                }
            },
            {
                path: 'create-poll', // New Poll (desabilitar mobile)  
                component: PollCreationComponent,
                canActivate: [RoleGuardService],
                data: {
                    expectedRole: ['admin', 'sponsor', 'super'],
                    expectedDevice: ['D']
                }
            },
            {
                path: 'poll/create/partner/:type',
                component: PollCreationPartnerComponent,
                canActivate: [RoleGuardService],
                data: {
                    expectedRole: ['admin', 'sponsor', 'super'],
                    //expectedDevice: ['S', 'T', 'D']  
                    expectedDevice: ['D']
                }
            },
            {
                path: 'create-poll/board/:type',
                component: PollCreationBoardComponent,
                canActivate: [RoleGuardService],
                data: {
                    expectedRole: ['admin', 'sponsor', 'super'],
                    //expectedDevice: ['S', 'T', 'D']  
                    expectedDevice: ['D']
                }
            },
            {
                path: 'create-poll/generic',
                component: PollCreationGenericComponent,
                canActivate: [RoleGuardService],
                data: {
                    expectedRole: ['admin', 'sponsor', 'super'],
                    //expectedDevice: ['S', 'T', 'D']  
                    expectedDevice: ['D']
                }
            },
            // {  
            //   path: 'admin-poll-details', // DEPOIS JUNTAR AS ROTAS POLL-DETAILS E ESSA. USAR O MESMO CONTROLLER  
            //   component: AdminPollDetailsComponent  
            // },  
            {
                path: 'edit-poll/generic/:id',
                component: EditPollGenericComponent,
                canActivate: [RoleGuardService],
                data: {
                    expectedRole: ['admin', 'sponsor', 'super'],
                    //expectedDevice: ['S', 'T', 'D']  
                    expectedDevice: ['D']
                }
            },
            {
                path: 'edit-poll/partner/:id',
                component: EditPollPartnerComponent,
                canActivate: [RoleGuardService],
                data: {
                    expectedRole: ['admin', 'sponsor', 'super'],
                    //expectedDevice: ['S', 'T', 'D']  
                    expectedDevice: ['D']
                }
            },
            {
                path: 'edit-poll/board/:id',
                component: EditPollBoardComponent,
                canActivate: [RoleGuardService],
                data: {
                    expectedRole: ['admin', 'sponsor', 'super'],
                    //expectedDevice: ['S', 'T', 'D']  
                    expectedDevice: ['D']
                }
            },
            {
                path: 'approve-poll/:id',
                component: ApprovePollComponent,
                canActivate: [RoleGuardService],
                data: {
                    expectedRole: ['admin', 'sponsor', 'super'],
                    //expectedDevice: ['S', 'T', 'D']  
                    expectedDevice: ['D']
                }
            },
            {
                path: 'results', // Results  (habilitar mobile)  
                component: ResultDashboardComponent,
                canActivate: [RoleGuardService],
                data: {
                    expectedRole: ['lawyer', 'super'],
                    expectedDevice: ['S', 'T', 'D']
                }
            },
            {
                path: 'results/:id',
                component: ResultPollComponent,
                canActivate: [RoleGuardService],
                data: {
                    expectedRole: ['lawyer', 'super'],
                    expectedDevice: ['S', 'T', 'D']
                }
            },
            {
                path: 'about', // About  (habilitar mobile)  
                component: AboutComponent,
                canActivate: [RoleGuardService],
                data: {
                    expectedRole: ['admin', 'partner', 'sponsor', 'lawyer', 'super'],
                    expectedDevice: ['S', 'T', 'D']
                }
            }]
    }
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule { }  
