<h3 class="title-my-polls">My polls</h3>
    <div *ngIf="pollExists" class="card-columns-2 card-my-polls">
        <div  class="card" *ngFor="let poll of polls"><span class="header-line gradient-color-1"></span>

            <div class="card-header">
                    <p class="float-right label-end-date">End date: {{poll.poll.end_date | dateTimeFormatFilter : "MMM DD, YYYY HH:mm"}}</p>

            <span class="badge" [ngClass]="{
                'badge-success': poll.daysRemaining >= 70,
                'badge-warning': poll.daysRemaining < 70,
                'badge-danger': poll.daysRemaining < 20}">
                {{poll.daysRemaining | DaysRemainingPipe | uppercase}}</span>

            </div>
            <div class="card-body">
                <h6 class="text-muted card-subtitle mb-2 label-size-medium">{{poll.poll.pollType | PollTypePipe}}</h6>
                <h5 class="titleCut card-title label-size-large">{{poll.poll.name}}</h5>
                <p class="textCut text-left card-text label-size-small">{{poll.poll.description}}</p>
            </div>
            <div class="card-footer card-footer-vote">
                <div class="btn-group d-lg-flex justify-content-lg-center" role="group">
                    <a class="btn btn-success btn-vote" role="button" routerLink="../poll/{{poll.poll.id}}">Vote Now&nbsp;                        
                        <div class="icon-action icon-checkbox-white"></div>
                    </a>

                </div>
            </div>
        </div>
    </div>
    <div *ngIf="!pollExists" class="card-columns-2 poll-card">
            <div  class='overlay-my-polls'>
                    <div class="spinner-my-polls">
                        There are no polls
                    </div>
                </div>
        </div>
