import { Component, OnInit } from '@angular/core';  
import { DataService } from '../data.service';  
import { ActivatedRoute } from '@angular/router';  
import { Location } from '@angular/common';  
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';  
import { NgForm } from '@angular/forms';  
import { NotificationService } from '../services/notification.service';  
import { AuthService } from '../auth.service';  
import { User } from '../interfaces/user';  
import { Voter } from '../interfaces/voter';  
import swal from 'sweetalert2';  
import * as _ from 'lodash';  
import * as fileSaver from 'file-saver-es';  
  
@Component({  
  selector: 'app-poll-details',  
  templateUrl: './poll-details.component.html',  
  styleUrls: ['./poll-details.component.scss']  
})  
export class PollDetailsComponent implements OnInit {  
  
  //#region Variables
  public poll_detail: any = {  
    'name': '',  
    'saveType': '',  
    'id': '',  
    'description': '',  
    'created_by': '',  
    'subtitle': '',  
    'title': '',  
    'created_date': '',  
    'begin_date': '',  
    'end_date': '',  
  };
  emailContent = {  
    'to_address': '',  
    'message_content': '',  
    'poll_id': '',  
    'subject': '',  
    'user_pwc_guid': ''  
  };
  public candidates: Object;  
  selectedVoters: Voter[] = [];  
  voterSelected: Voter[] = [];  
  loader: Boolean = false;  
  failed: Boolean = false;  
  user: User;  
  link: any[] = [];  
  fName: any[] = []; 
  nullFile: boolean[] = [];  
  nullPhoto: boolean[] = [];  
  photoBytes: any[] = [];  
  votersN: any;  
  votedCount: number = 0;  
  notVotedCount: number = 0;  
  activeModal: NgbModalRef;  // Adicionando a referência do modal ativo
  //#endregion Variables
  
  constructor(private data: DataService, private notificationService: NotificationService, private auth: AuthService,  
    private route: ActivatedRoute, private location: Location, private modalService: NgbModal) {  
  }

  //#region Action Buttons
  goBack() {  
    window.history.back();  
  }  
  //#endregion Action Buttons


  //#region Candidates
  getDownload(fileName: any[], link: any[]) {
    this.loader = true
    for(let i = 0; i< fileName.length;i++){
      this.loader = true
      this.data.getDownload(fileName[i], link[i]).subscribe((data: any) => {
        let blob:any = new Blob([data], { type: 'text/json; charset=utf-8' });
        const url = window.URL.createObjectURL(blob);
        fileSaver.saveAs(blob, fileName[i])
        if(i == fileName.length - 1) this.loader = false
        },
        error => {
          console.log("erro", error.toString());
          this.loader = false
        });
    }
  }

  getDownloadBusinessCase(fileName: string, link: string) {
    this.loader = true
    this.data.getDownload(fileName, link).subscribe((data: any) => {
      let blob: any = new Blob([data], { type: 'text/json; charset=utf-8' });
      const url = window.URL.createObjectURL(blob);
      fileSaver.saveAs(blob, fileName)
      this.loader = false
    },
        error => {
            console.log("erro", error.toString());
            this.loader = false
        });
} 
  
  getDownloadPhoto(i: number) {  
    if (this.poll_detail.candidates[i].attachments[0].link != null) {  
      this.data.getDownload('photo', this.poll_detail.candidates[i].attachments[0].link).subscribe((data: any) => {  
        let blob: any = new Blob([data], { type: 'image/gif' });  
        var reader = new FileReader();  
        reader.readAsDataURL(blob);  
        reader.onloadend = (event: any) => {  
          this.photoBytes[i] = event.target.result;  
        }  
      });  
    }  
  }  
  //#endregion Candidates


  //#region Email
  sendEmail(f: NgForm) {  
    this.activeModal.close();  // Fechando o modal ativo
    this.loader = true;  
    this.emailContent.message_content = f.value.message;  
    this.emailContent.poll_id = this.poll_detail.id;  
    this.emailContent.subject = 'Deadline is coming, vote now!';  
    this.loader = true;  
    
    if (f.valid) {  
      this.emailContent.to_address = '';  
      this.emailContent.user_pwc_guid = this.user.guid;
      this.voterSelected.forEach((voter: any) => {  
        this.emailContent.to_address += voter.email + ';';  
      });  
    
      this.notificationService.sendEmail(this.emailContent).subscribe(  
        data => {  
          swal.fire({ title: '', text: 'The e-mail has been sent.', icon: 'success', confirmButtonColor: '#d04a02' });  
          this.loader = false;  
        },  
        error => {  
          swal.fire({  
            title: 'Oops...',  
            html: '<p>System was unable to send e-mail for some of the voters. Double check the Last Send information for each of the voters that has status pending.</p>',  
            icon: 'error', confirmButtonColor: '#d04a02'  
          });  
          this.loader = false;  
        }  
      );  
    }  
    
    if (this.voterSelected.length === 0) {  
      this.loader = false;  
    }  
  } 
  //#endregion Email


  //#region OnInit
  public ngOnInit() {  
    this.user = this.auth.getUserInfo();  
    this.getDetail();  
  }
  //#endregion OnInit


  //#region Load
  public getDetail() {  
    const id = this.route.snapshot.paramMap.get('id');
    this.data.getPoll(id).subscribe(data => {  
      console.log(data); // Verificar os dados recebidos  
      this.poll_detail = data;
      if (this.poll_detail.attachments && this.poll_detail.attachments.length > 0) {  
        if (this.poll_detail.attachments[0]) {  
          for(let i = 0; i< this.poll_detail.attachments.length;i++){
            this.link[i] = this.poll_detail.attachments[i].link
            this.fName[i] = this.poll_detail.attachments[i].name;
            console.log(this.fName[i])
          } 
        }  
      }  
    
      if (this.poll_detail.candidates && this.poll_detail.candidates.length > 0) {  
        this.poll_detail.candidates.forEach((candidate: any, index: number) => {  
          console.log(candidate); // Verificar a estrutura do candidato  
          this.nullPhoto[index] = !(candidate.attachments && candidate.attachments[0] && candidate.attachments[0].link);  
          this.nullFile[index] = !(candidate.attachments && candidate.attachments[1] && candidate.attachments[1].link);  

          if (!this.nullPhoto[index]) {  
            this.getDownloadPhoto(index);  
          }  
        });  
      }

      this.votersN = this.poll_detail.voters;    
      this.votersN.forEach((voter: any) => {  
        if (voter.voted) {  
          this.votedCount++;  
        }  
      });  
    
      this.notVotedCount = this.votersN.length - this.votedCount;
    }, error => {  
      console.log(error);  
    });  
  }  
  //#endregion Load


  //#region Modal
  openModal(content: any, v: any) {  
    if (this.activeModal) {
      this.activeModal.close();
    }
  
    this.activeModal = this.modalService.open(content, { centered: true });
    this.overrideModal();
    
    if(v == undefined){
      this.voterSelected = [];
      for(let voter of this.poll_detail.voters)
        if (!voter.voted) this.voterSelected.push(voter)
    }
    else
      this.voterSelected = [v];
  }

  overrideModal(){
    //sobreescreve z-index, para poder interagir com o modal
    setTimeout(() => {
      const backdrop = document.querySelector('.modal-backdrop');
      if(backdrop)
        (backdrop as HTMLElement).style.zIndex = '1050';
    }, 0);
  }
  //#endregion Modal
}  
