import { Component, OnInit } from '@angular/core';
import { DataService } from '../data.service';
import { AuthService } from '../auth.service';
import { User } from '../interfaces/user';
import { HttpClient } from '@angular/common/http';
import swal from 'sweetalert2';
import { Router } from '@angular/router';
import {PollSaveType} from '../utils/poll-save-type.enum'
@Component({
  selector: 'app-admin-dashboard',
  templateUrl: './admin-dashboard.component.html',
  styleUrls: ['./admin-dashboard.component.scss']
})
export class AdminDashboardComponent implements OnInit {

  //#region Variables
  polls$: any;
  countPolls$ = 0;
  pendingPolls$: number;
  countDraft = 0;
  countActive = 0;
  countPending = 0;
  countAction = 0;
  countClosed = 0;
  countAccounted = 0;
  countApproved = 0;
  user: User;
  status: string;
  poll_data = {};
  loader = false;
  error = false;
  success = false;
  saveAction: string;

  pollId = {
    'pollId': '',
    'current_user': ''
  };
  //#endregion Variables

  constructor(private data: DataService,  private auth: AuthService, private router: Router, private http: HttpClient) { }

  confirmSavedPoll() {
    this.loader = false;
    this.success = true;
    setTimeout(() => {
        this.success = false;
      }
        , 2000);
  }

  pollErrorMessage() {
    this.loader = false;
    this.error = true;
    setTimeout(() => {
        this.error = false;
      }
        , 2000);
  }


  //#region Load
  public getAllPolls(){
    this.countPolls$ = 0;
    this.countDraft = 0;
    this.countActive = 0;
    this.countPending = 0;
    this.countAction = 0;
    this.countClosed = 0;
    this.countAccounted = 0;
    this.countApproved = 0;

    this.data.getPollsAdmin().subscribe(data => {
      this.polls$ = data;
      this.countPolls$ = Object.keys(this.polls$).length;

      for ( let i of Object.keys(this.polls$)) {
          if (this.polls$[i].saveType === PollSaveType.Draft) {
            this.countDraft++;
          } else if (this.polls$[i].saveType === PollSaveType.PendingApproval) {
            this.countPending++;
          } else if (this.polls$[i].saveType === PollSaveType.Approved) {
            this.countApproved++;
          } else if (this.polls$[i].saveType === PollSaveType.ActionRequired) {
            this.countAction++;
          } else if (this.polls$[i].saveType === PollSaveType.Active) {
            this.countActive++;
          } else if (this.polls$[i].saveType === PollSaveType.Close) {
            this.countClosed++;
          } else {
            this.countAccounted++;
          }
      }

      this.polls$ = this.polls$.filter(function(e: any) {
        return e.saveType < PollSaveType.Completed;
      });
    },    
    error => {
    //   console.log(error)
    });
  }
  //#endregion Load


  //#region OnInit
  ngOnInit() {
    this.user = this.auth.getUserInfo();
    this.getAllPolls();
  }
  //#endregion OnInit


 //#region Action Buttons
  cancelPoll(poll_id: any) {
    swal.fire({
        title: 'Are you sure?',
        text: 'The poll will be cancelled',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '##d04a02',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, I am sure.'
      }).then((result: any) => {
        if (result.value) {
          this.loader = true;
          this.saveAction = 'cancelled';
          this.pollId.current_user = this.user.guid;
          this.data.cancelPoll(poll_id).subscribe(data => {
              this.loader = false;
              swal.fire({
                  title: 'Cancelled!',
                  text: 'The poll has been cencelled.',
                  icon: 'success',
                  confirmButtonColor: '##d04a02'
              })
              .then(() => {
                  location.reload();
              });
              // this.getAllPolls();
          },
          error => {
              this.loader = false;
              swal.fire({title: 'Oops...',
              html: '<p>We are sorry, an error has occurred. Please contact the system administrators.</p>' +
              '<br><div class="alert alert-danger" role="alert"><p> Error: </p><br>' + error.error.message + '<br><br>'
              + error.message + '</div>',
              icon: 'error', confirmButtonColor: '#d04a02'})
              .then(() => {
                // this.router.navigate(['/admin']);
              });
          });
        }
      });      
  }

  deletePoll(poll_id: any) {
    swal.fire({
      title: 'Are you sure?',
      text: "All poll information will be lost.",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#d04a02',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, I am sure.'
    }).then((result: any) => {
      if (result.value) {
        this.loader = true;
        this.saveAction = 'deleted';
        this.pollId.current_user = this.user.guid;
        this.data.deletePoll(poll_id).subscribe(data => {
          this.loader = false;
          swal.fire({
            title: 'Deleted!',
            text: 'The poll has been deleted.',
            icon: 'success',
            confirmButtonColor: '#d04a02'
          })
          .then(() => {
              location.reload();
          });
        },
        error => {
          console.log(error.error.message)
          console.log(error.message)
          this.loader = false;
          swal.fire({title: 'Oops...',
            html: '<p>We are sorry, an error has occurred. Please contact the system administrators.</p>' +
            '<br><div class="alert alert-danger" role="alert"><p> Error: </p><br>' + error.error.message + '<br><br>'
            + error.message + '</div>',
            icon: 'error', confirmButtonColor: '#d04a02'})
            .then(() => {
              // this.router.navigate(['/admin']);
            });
        });
      }
    });
  }
  //#endregion Action Buttons
}
