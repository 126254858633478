import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { DataTablesModule } from 'angular-datatables';
import { RouterModule } from '@angular/router';
// Import ng-voting  
import { NgVotingComponent } from 'ng-voting';
import { ModalModule } from 'ngx-bootstrap/modal';
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { DeviceDetectorService } from 'ngx-device-detector';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LayoutComponent } from './layout/layout.component';
import { LoginComponent } from './login/login.component';
import { AccessDeniedComponent } from './access-denied/access-denied.component';
import { AdminDashboardComponent } from './admin-dashboard/admin-dashboard.component';
import { PartnerPollsComponent } from './partner-polls/partner-polls.component';
import { PollCreationComponent } from './poll-creation/poll-creation.component';
import { PollDetailsComponent } from './poll-details/poll-details.component';
import { PollComponent } from './poll/poll.component';
import { AdminPartnersSelectionModalComponent } from './admin-partners-selection-modal/admin-partners-selection-modal.component';
import { PersonItemComponent } from './admin-person/person-item.component';
import { PersonListComponent } from './admin-person/person-list.component';
import { GroupItemComponent } from './admin-person/group-item.component';
import { ApprovePollComponent } from './approve-poll/approve-poll.component';
import { ResultDashboardComponent } from './result-dashboard/result-dashboard.component';
import { ResultPollComponent } from './result-poll/result-poll.component';
import { AboutComponent } from './about/about.component';
import { AdminHistoryComponent } from './admin-history/admin-history.component';
import { PollCreationPartnerComponent } from './poll-creation-partner/poll-creation-partner.component';
import { PollCreationBoardComponent } from './poll-creation-board/poll-creation-board.component';
import { PollCreationGenericComponent } from './poll-creation-generic/poll-creation-generic.component';
import { EditPollPartnerComponent } from './edit-poll-partner/edit-poll-partner.component';
import { EditPollBoardComponent } from './edit-poll-board/edit-poll-board.component';
import { EditPollGenericComponent } from './edit-poll-generic/edit-poll-generic.component';
import { SidebarComponent } from './sidebar/sidebar.component';
import { PollTypePipe } from './poll-type.pipe';
import { EndDatePipe } from './end-date.pipe';
import { DaysRemainingPipe } from './days-remaining.pipe';
import { PollstatusPipe } from './pollstatus.pipe';
import { UserProfilePipe } from './user-profile.pipe';
import { UserStatusPipe } from './user-status.pipe';
import { DateTimeFormatFilterPipe } from './date-time-format-filter.pipe';
import { AuthService } from './auth.service';
import { RoleGuardService } from './RoleGuard.service';
import { AuthInterceptorService } from './services/auth-interceptor.service';
import { PollService } from './services/poll.service';
import { NotificationService } from './services/notification.service';
import { AttachmentService } from './services/attachment.service';
import { DateUtil } from './utils/date.util';
import { BnNgIdleService } from 'bn-ng-idle'; // import bn-ng-idle service  
import { BookingService } from './services/sidebar.service';
import { PersonService } from './services/person.service';
import { GroupService } from './services/group.service';
import { AuthGuard } from './services/auth.guard';
import { DataService } from './data.service';

@NgModule({
    declarations: [
        AppComponent,
        SidebarComponent,
        PartnerPollsComponent,
        PollComponent,
        PollDetailsComponent,
        AdminDashboardComponent,
        PollCreationComponent,
        AdminPartnersSelectionModalComponent,
        PersonItemComponent,
        PersonListComponent,
        GroupItemComponent,
        LoginComponent,
        LayoutComponent,
        AccessDeniedComponent,
        PollTypePipe,
        EndDatePipe,
        ApprovePollComponent,
        DaysRemainingPipe,
        PollstatusPipe,
        ResultDashboardComponent,
        ResultPollComponent,
        AboutComponent,
        AdminHistoryComponent,
        PollCreationPartnerComponent,
        PollCreationBoardComponent,
        PollCreationGenericComponent,        
        EditPollPartnerComponent,        
        EditPollBoardComponent,
        EditPollGenericComponent,
        UserProfilePipe,
        UserStatusPipe,
        DateTimeFormatFilterPipe
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        HttpClientModule,
        NgVotingComponent,
        ModalModule.forRoot(),
        BrowserAnimationsModule,
        ReactiveFormsModule,
        FormsModule,
        SweetAlert2Module.forRoot(),
        NgbModule,
        DataTablesModule,
        RouterModule,
        NgMultiSelectDropDownModule.forRoot(),
    ],
    providers: [
        AuthService,
        PollService,
        NotificationService,
        AttachmentService,
        DateUtil,
        RoleGuardService,
        BookingService,
        BnNgIdleService,
        PersonService,
        GroupService,
        AuthGuard,
        DataService,
        DeviceDetectorService,
        { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptorService, multi: true },
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }  
