<h3 class="title">Groups</h3><br>
<div class="row">
    <div class="col-lg-2">
        <a routerLink="/admin/group" class="btn btn-block btn-add">
            <div class="icon-action icon-plus-white"></div> Add new group
        </a>        
    </div>
</div>
<div class="row">
<hr>
</div>
<div *ngIf="itens" class="row">
    <div class="col-lg-12">
        <table datatable class="table table-condensed table-bordered table-hover">
            <thead>
                <tr>
                    <th>Group Name</th>
                    <th>Status</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let item of itensGroup">
                    <td>
                        <a class="content-list" [routerLink]="['/admin/group', item.id]">
                            <div class="icon-edit-orange"></div> {{item.groupname}}
                        </a>
                    </td>
                    <td>{{item.active | userStatus}}</td>
                </tr>
            </tbody>
        </table>
    </div>
</div>
<br>
<br>
<h3 class="title">Users Settings</h3><br>
<div class="row">
    <div class="col-lg-2">
        <a routerLink="/admin/person" class="btn btn-block btn-add">
            <div class="icon-action icon-plus-white"></div> Add new user
        </a>
    </div>
    <div class="col-lg-2">
        <button class="btn btn-block btn-upload" (click)="upload()">
            <div class="icon-action icon-upload-white"></div>Upload .csv
        </button>
    </div>
</div>
<div class="row">
<hr>
</div>
<div *ngIf="itens" class="row">
    <div class="col-lg-12">
        <table datatable class="table table-condensed table-bordered table-hover">
            <thead>
                <tr>
                    <th>Guid</th>
                    <th>Nome</th>
                    <th>E-mail</th>
                    <th>Profile</th>
                    <th>Grupo</th>
                    <th>Status</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let item of itens">
                    <td>
                        <a class="content-list" [routerLink]="['/admin/person', item.id]">
                            <div class="icon-edit-orange"></div> {{item.pwc_guid}}
                        </a>
                    </td>
                    <td>{{item.name}}</td>
                    <td>{{item.email}}</td>
                    <td>{{item.profile | userProfile}}</td>
                    <td>{{item.groupname}}</td>
                    <td>{{item.active | userStatus}}</td>
                </tr>
            </tbody>
        </table>
    </div>
</div>
