import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { GroupService } from '../services/group.service';
import swal from 'sweetalert2';

@Component({
    selector: 'app-group-item',
    templateUrl: './group-item.component.html',
    styleUrls: ['./group-item.component.scss']
})
export class GroupItemComponent implements OnInit {

    //#region Variables
    count: number;
    public item: any = {};
    loader = false;
    isNameInvalid = false; // Variável para controlar a validação do campo nome
    //#endregion Variables

    constructor(private groupService: GroupService, private route: ActivatedRoute, private router: Router) {
        this.route
            .params
            .subscribe(p => {
                if (p.id) {
                    this.groupService
                        .get(p.id)
                        .subscribe(group => {
                            this.item = group;
                        });
                }
            });
    }

    //#region Button Actions
    cancel() {
        swal.fire({
            title: 'Are you sure?',
            text: "All information will be lost.",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#d04a02',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, I am sure.'
        }).then((result: any) => {
            if (result.value) {
                this.router.navigate(['/settings']);
            }
        });
    }

    public save(): void {
        if(this.validateFields())
        {
            this.loader = true;
            if(isNaN(this.item.id)) {
                this.groupService.save(this.item).subscribe(() => {
                    this.loader = false;
                    swal.fire({ title: '', text: 'Successfully saved.', icon: 'success', confirmButtonColor: '#d04a02' })
                    .then(() => {
                        this.router.navigate(['/settings']);
                    });
                },
                (error) => {
                    this.loader = false;
                    this.handleErrorResponse(error);
                });
            }
            else{
                this.groupService.update(this.item).subscribe(() => {
                    this.loader = false;
                    swal.fire({ title: '', text: 'Successfully saved.', icon: 'success', confirmButtonColor: '#d04a02' })
                    .then(() => {
                        this.router.navigate(['/settings']);
                    });
                },
                (error) => {
                    this.loader = false;
                    this.handleErrorResponse(error);
                });
            }
        }
    }    
    //#endregion Button Actions


    //#region Error Handle
    private handleErrorResponse(error: any) {
        let errorMessage = 'An unknown error occurred!';

        if (error.error && error.error.message) {
            // Mensagem do backend
            errorMessage = error.error.message;
        } else if (typeof error === 'string') {
            // Erro como string
            errorMessage = error;
        }

        swal.fire({ title: 'Error', text: errorMessage, icon: 'error', confirmButtonColor: '#d04a02' });
    }
    //#endregion Error Handle


    //#region OnInit
    ngOnInit() {
    }
    //#endregion OnInit


    //#region ValidateFields
    validateFields(){
        this.isNameInvalid = !this.item.groupname; // Verifica se o nome está em branco
        if (this.isNameInvalid)
            return false;
        
        return true;
    }
    //#endregion ValidateFields
}
