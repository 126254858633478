<div>
    <div class="poll-back-padding">
        <a class="poll-link-back" (click)="goBack()"><b><</b><u>&nbsp;Voltar</u></a>
    </div>
    <h2>Edit poll draft&nbsp;</h2>
    <p class="poll-sub-title">Fill out the form below in order to create a new poll</p>
    <div *ngIf="partnerForm.saveType == 3" class="alert alert-warning alert-dismissible fade show" role="alert">
        <div class="icon-action icon-exclamation-grey"></div> <strong> Sponsor message:</strong> {{partnerForm.approver_message}}
    </div>

    <form>
        <h3 class="form-division-heading"><span class="form-step">1</span>Basic Information</h3>
        <!-- Title -->
        <div class="form-group"><label>Title</label>
            <input type="text" placeholder="Poll title" maxlength="128" class="form-control poll-form-fields" [(ngModel)] = "partnerForm.name" 
                name="name" [ngClass]="{ 'is-invalid': submitted && errors.name }"/>
            <div *ngIf="submitted && errors.name" class="invalid-feedback"> Title is required </div>
        </div>

        <!-- Description -->
        <div class="form-group"><label>Description</label>
            <textarea placeholder="Poll description" class="form-control poll-form-fields" [(ngModel)] = "partnerForm.description" 
                name="description" [ngClass]="{ 'is-invalid': submitted && errors.description }"></textarea>
            <div *ngIf="submitted && errors.description" class="invalid-feedback"> Description is required </div>
        </div>

        <!-- Date-time Begin End-->
        <div class="form-row" [ngClass]="{ 'poll-dataInvalida': startEarlierEnd || oneHour }">
            <!-- Start date -->
            <div class="col">
                <div class="form-group"><label>Start date</label>
                    <div class="ngb-tp poll-datetime poll-datetime-position">
                        <div _ngcontent-c3="" class="ngb-tp-input-container ngb-tp-hour">
                            <select class="form-control" [(ngModel)]="partnerForm.begin_time"
                                name="begin_time" [ngClass]="{ 'is-invalid': submitted && errors.begin_time }">
                                <option *ngFor="let hour of validHours" [value]="hour">{{hour}}</option>
                            </select>
                        </div>
                        <div _ngcontent-c3="" class="ngb-tp-spacer poll-datetime-colon"> : </div>
                        <div _ngcontent-c3="" class="ngb-tp-input-container ngb-tp-minute poll-datetime-minute">
                            <input _ngcontent-c3="" disabled aria-label="Minutes" class="form-control" maxlength="2"
                                placeholder="00" type="text">
                        </div>
                    </div>
                    <div class="poll-calendar-start">
                        <ngb-datepicker [firstDayOfWeek]="0" [ngClass]="{ 'is-invalid': startRequired }"
                            [(ngModel)]="partnerForm.begin_date" [minDate]="minDate" name="begin_date">
                        </ngb-datepicker>
                        <div *ngIf="startRequired" class="poll-message-error">{{msgErr}}</div>
                    </div>
                </div>
            </div>

            <!--End date -->
            <div class="col">
                <div class="form-group"><label>End date</label>
                    <div class="ngb-tp poll-datetime poll-datetime-position">
                        <div _ngcontent-c3="" class="ngb-tp-input-container ngb-tp-hour">
                            <select class="form-control" [(ngModel)]="partnerForm.end_time"
                                name="end_time" [ngClass]="{ 'is-invalid': submitted && errors.end_time }">
                                <option *ngFor="let hour of validHours" [value]="hour">{{hour}}</option>
                            </select>
                        </div>
                        <div _ngcontent-c3="" class="ngb-tp-spacer poll-datetime-colon"> : </div>
                        <div _ngcontent-c3="" class="ngb-tp-input-container ngb-tp-minute poll-datetime-minute">
                            <input _ngcontent-c3="" disabled aria-label="Minutes" class="form-control" maxlength="2"
                                placeholder="00" type="text">
                        </div>
                    </div>
                    <div class="poll-calendar-end" [ngClass]="{ 'is-invalid': submitted && errors.end_date }">
                        <ngb-datepicker [firstDayOfWeek]="0" [(ngModel)]="partnerForm.end_date" [minDate]="minDate" 
                            name="end_date" [ngClass]="{ 'is-invalid': endRequired }"></ngb-datepicker>
                        <div *ngIf="endRequired" class="poll-message-error">{{msgErr}}</div>
                    </div>
                </div>
            </div>
        </div>
        <div *ngIf="startEarlierEnd || oneHour" class="poll-message-error">{{msgErr}}</div>

        <!-- Email -->
        <div class="form-group"><label>E-mail message</label>
            <textarea placeholder="Write down the email message for the voters" autofocus=""
                [(ngModel)]="partnerForm.email" class="form-control poll-form-fields" name="email"
                [ngClass]="{ 'is-invalid': submitted && errors.email }"></textarea>
            <div *ngIf="submitted && errors.email" class="invalid-feedback"> Email Message is required </div>
        </div>

        <!-- Candidates -->
        <h3 class="form-division-heading"><span class="form-step">2</span>Candidates Information</h3>
        <div class="row">
            <div class="form-group col-sm-3" *ngFor="let candidate of partnerForm.candidates; let i=index">
                <div class="panel panel-default">
                    <div class="panel-heading">
                        <span class="pull-right icon-action icon-close-orange" (click)="removeCandidate(i)"></span>
                    </div>
                    <div class="panel-body poll-card-candidate">
                        <div id="profile-container">
                            <img *ngIf="candidate.photo == '' || candidate.photo === null" class="img-thumbnail poll-no-img-candidate">
                            <img *ngIf="candidate.photo != null" class="img-thumbnail" src="{{candidate.photoBytes}}">
                        </div>
                        <input type="text" placeholder="Candidate Name" [value]="candidate.candidatename"
                            class="form-control" disabled />
                        <select class="form-control" [value]="candidate.candidateLos" disabled>
                            <option disabled selected value>Select the LOS</option>
                            <option>Advisory</option>
                            <option>Assurance</option>
                            <option>Tax</option>
                            <option>IFS</option>
                            <option>TSP Office</option>
                            <option>-</option>
                        </select>
                        <textarea placeholder="Candidate description" [value]="candidate.candidatedesc" autofocus
                            class="form-control" disabled></textarea>
                        <button type="button" *ngIf="candidate.businesscase != ''"
                            (click)="getDownload(candidate.fName, candidate.businesscase)" class="btn poll-btn-download-business-case">
                            Download Business Case &nbsp;<div class="icon-action icon-download-grey"></div>
                        </button>
                    </div>
                </div>
            </div>
            <!-- Add Candidates -->
            <div class="form-group col-sm-3">
                <div class="panel panel-default" [ngClass]="{ 'is-invalid': submitted && errors.candidates }">
                    <div class="panel-body poll-card-candidate">
                        <div id="profile-container">
                            <img *ngIf="newCandidate.photo.name == 'File'" class="img-thumbnail poll-no-img-candidate">
                            <img *ngIf="newCandidate.photo.name != 'File'" class="img-thumbnail"
                                src="{{newCandidate.photoBytes}}">
                            <button type="button" class="btn btn-sm poll-btn-new-photo" (click)="photoInput.click()">
                                New photo <div class="icon-action icon-search-white"></div>
                                <input class="poll-btn-display" type="file" (change)="onPhotoChanged($event)" #photoInput>
                            </button>
                        </div>
                        <input type="text" placeholder="Candidate Name" [(ngModel)]="newCandidate.candidatename" 
                            class="form-control" name="candidatename" maxlength="128"/>
                            <select class="form-control" [(ngModel)]="newCandidate.candidateLos" name="candidatelos">
                                <option disabled selected value>Select the LOS</option>
                                <option>Advisory</option>
                                <option>Assurance</option>
                                <option>Tax</option>
                                <option>IFS</option>
                                <option>TSP Office</option>
                                <option>-</option>
                            </select>
                        <textarea placeholder="Candidate description" [(ngModel)]="newCandidate.candidatedesc" 
                            autofocus class="form-control" name="candidatedesc" maxlength="255"></textarea>
                        <button type="button" *ngIf="newCandidate.businesscase != ''"
                            (click)="getDownload(newCandidate.fName, newCandidate.businesscase)" class="btn poll-btn-download-business-case">
                            Download Business Case &nbsp;<div class="icon-action icon-download-grey"></div>
                        </button>
                        <button type="button" class="btn btn-sm poll-btn-new-photo" (click)="fileInput.click()">
                            New Business Case File <div class="icon-action icon-search-white"></div>
                            <input class="poll-btn-display" type="file" (change)="onFileChanged($event)" #fileInput>
                        </button>
                        <hr>
                        <div *ngIf="newCandidate.candidatename != '' && newCandidate.candidateLos != ''">
                            <button class="btn poll-btn poll-btn__btn-confirm-candidate" type="button"
                                (click)="addCandidate()">Confirm candidate</button>
                        </div>
                        <div *ngIf="newCandidate.candidatename == '' || newCandidate.candidateLos == ''">
                            <button class="btn poll-btn-candidate-add" type="button">Confirm candidate</button>
                        </div>
                    </div>
                </div>
                <div *ngIf="submitted && errors.candidates" class="invalid-feedback candidates"> At least one Candidate is required </div>
            </div>
        </div>

        <!-- Group -->
        <form [formGroup]="tobForm" (ngSubmit)="onSubmit($event, '')">
            <div class="form-group poll-title-section">
                <h3 class="form-division-heading"><span class="form-step">2</span>Select Groups</h3>
            </div>
            <div [ngClass]="{ 'poll-groupError poll-groupError-dropdown': groupError }">
                <ng-multiselect-dropdown
                        [placeholder]="'Select Groups'"
                        [settings]="groupSettings"
                        [data]="pollDataGroups"
                        [(ngModel)]="selectedGroupItems"        
                        (onSelect)="onGroupItemSelect($event)"
                        (onDeSelect)="onGroupItemDeSelect($event)"
                        (onSelectAll)="onGroupSelectAll($event)"
                        (onDeSelectAll)="onGroupDeSelectAll($event)"
                        formControlName="selectedGroups">
                    </ng-multiselect-dropdown>
                <div *ngIf="groupError" class="poll-message-error">
                    Select Groups
                </div>
            </div>
            <br>
        </form>

        <!-- Voters -->
        <div class="form-group poll-title-section">
            <h3 class="form-division-heading"><span class="form-step">4</span>Voters Information</h3>
            <span class="badge poll-total-count">{{countVoters}} voters selected</span>
        </div>
        <div class="row">
            <div [ngClass]="{ 'poll-voterError': submitted && errors.selectedVoters }">
                <a class="btn poll-btn" (click)="loadVoters()">
                    Load Voters
                </a>
                <div *ngIf="submitted && errors.selectedVoters" class="poll-message-error">
                    Voters are required
                </div>
            </div>

            <div class="col-sm-12" *ngIf="loadingVoter">
                <div class="spinner">
                    <div class="spinnerInner">
                        <img src="..\assets\img\loader_old.gif">
                    </div>
                </div>
            </div>

            <div [hidden]="!countShowVoterTable" class="col-sm-12">
                <table id="tabelaVoters" datatable class="table  table-bordered table-hover">
                    <thead>
                        <tr>
                            <th>Partner Name</th>
                        </tr>
                    </thead>
                    <tbody class="poll-content-list">
                    </tbody>
                </table>
            </div>
        </div>

        <!-- Action Button -->
        <a class="float" id="menu-share">
            <div class="poll-icon-send icon-plus-white"></div>
        </a>
        <ul *ngIf="!saving">
            <li placement="left" ngbTooltip="Approve" *ngIf="user.role === 0 || user.role === 4">
                <a class="poll-btn-send-approved" role="button" (click)="onSubmit($event, 'Approved')" name="approval" id="2">                    
                    <div id="2" class="poll-icon-send icon-checkmark-white"></div>
                </a>
            </li>
            <li placement="left" ngbTooltip="Send for Approval" *ngIf="user.role === 1 || user.role === 4">
                <a class="poll-btn-send-pending-approval" role="button" (click)="onSubmit($event, 'PendingApproval')" name="approval" id="1">
                    <div id="1" class="poll-icon-send-air-plane icon-paper-airplane-white"></div>
                </a>
            </li>
            <li placement="left" ngbTooltip="Save as Draft">
                <a class="poll-btn-send-draft" role="button" (click)="onSubmit($event, 'Draft')" name="draft" id="0">
                    <div id="0" class="poll-icon-send icon-save-white"></div>
                </a>
            </li>
            <li placement="left" ngbTooltip="Cancel">
                <a class="poll-btn-send-cancel" (click)="cancel()">
                    <div class="poll-icon-send icon-close-white"></div>
                </a>
            </li>
        </ul>
    </form>

    <!-- Loader / Alert -->
    <div *ngIf="loader" class='overlay'>
        <div class="spinner">
            <div class="spinnerInner">
                <img src="..\assets\img\loader_old.gif">
            </div>
        </div>
    </div>
</div>