<div class="row page-about">  
    <div class="col-sm-9 text-center grid-about">  
        <div class="row text-center logo-pwc">  
            <!-- <img width="150" height="100" src="assets/img/PricewaterhouseCoopers_Logo.png" />   -->
            <br>  
            <h1 class="title-about">Voting</h1>  
        </div>  
        <br>  
        <div class="row text-center">  
            <div class="card text-center grid-header">  
                <div class="card-header"><b>Product Owner</b></div>  
                <div class="card-body">  
                    <h5 class="card-title"> PwC</h5>  
                </div>  
            </div>  
            <div class="card text-center grid-header">  
                <div class="card-header"><b>Category</b></div>  
                <div class="card-body">  
                    <h5 class="card-title">Voting Plataform</h5>  
                </div>  
            </div>  
            <div class="card text-center grid-header">  
                <div class="card-header"><b>Release Date</b></div>  
                <div class="card-body">  
                    <h5 class="card-title">February, 2025</h5>  
                </div>  
            </div>  
        </div>  
        <br>  
        <div class="row text-center">  
            <div class="card text-center" >  
                <div class="card-header"><b>Team</b></div>  
                <div class="card-body">  
                    <h5 class="card-title">PwC Technology</h5>  
                </div>  
            </div>  
            <div class="card text-center grid-header">  
                <div class="card-header"><b>Languages</b></div>  
                <div class="card-body">  
                    <h5 class="card-title">English</h5>  
                </div>  
            </div>  
            <div class="card text-center grid-header">  
                <div class="card-header"><b>Version</b></div>  
                <div class="card-body">  
                    <h5 class="card-title">2.0</h5>  
                </div>  
            </div>  
            <div class="card text-center grid-header">  
                <div class="card-header"><b>Last Update</b></div>  
                <div class="card-body">  
                    <h5 class="card-title">February, 2025</h5>  
                </div>  
            </div>  
        </div>  
        <br>   
    </div>  
  
    <div class="col-md-3 text-center sidebar-about">  
        <strong>Powered by</strong><br><br>  
        <br>
        <div class="img-about img-about__img-angularJs"></div>
        <br>
        <div class="img-about img-about__img-python"></div>
        <address class="sidebar-content">  
            <b>PricewaterhouseCoopers Brasil Ltda.</b><br /> Avenida Brigadeiro Faria Lima, 3732<br /> São Paulo, Brasil<br />  
            <br>
            <p > © {{currentYear}} PricewaterhouseCoopers Brasil Ltda. <br> Todos os direitos reservados.</p>  
        </address>  
        
    </div>  
</div>  
