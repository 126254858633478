<div class="poll-back-padding">
    <a class="poll-link-back" (click)="goBack()"><b><</b><u>&nbsp;Voltar</u></a>
</div>
    <h4>Ballot preview</h4>
    <h6>This is what the partner is going to see</h6>
<div class="row">
    <div class="col">
        <div class="border status-poll">
            
            <div class="card card-padding" *ngIf="generic">
                <div class="card-body">
                    <p class="text-right float-right poll-details-date">This poll will end on: {{pollData.end_date | dateTimeFormatFilter : "MMM DD, YYYY HH:mm"}}</p>
                    <h4 class="card-title">{{pollData.name}}<br></h4>
                    <h6 class="text-muted card-subtitle mb-2">{{pollData.description}}</h6>
                    <button type="button" *ngIf="link.length > 0" class="btn btn-light float-right btn-download"
                        (click)="getDownload(finantialReportFileName, link)"> Download <div class="icon-action icon-download-white"></div>
                    </button>
                </div>
                <div class="card-footer d-lg-flex justify-content-lg-center">
                    <div class="d-lg-flex justify-content-lg-center align-items-lg-center poll-btn-vote-generic-board-tsp">
                        <button class="btn btn-success btn-block btn-striped" disabled id="Y">YES</button>
                        <h5 class="d-flex float-none d-lg-flex justify-content-lg-center align-items-lg-center voting-or">&nbsp;or&nbsp;</h5>
                        <button class="btn btn-danger btn-block btn-striped"  disabled id="N">NO</button>
                    </div>
                </div>
            </div>

            <div class="card card-padding" [hidden]="!boardTsp">
                <form [formGroup]="tobVotingForm">
                    <div class="card-body">
                        <p class="text-right float-right poll-details-date">This poll will end on: {{pollData.end_date | dateTimeFormatFilter : "MMM DD, YYYY HH:mm"}}</p>
                        <h4 class="card-title">{{pollData.name}}<br></h4>
                        <h6 class="text-muted card-subtitle mb-2">Vacancies: {{pollData.vacancy}}</h6>
                        <p class="card-text">{{pollData.description}}&nbsp;<br></p>
                        <span class="badge total-count ">{{countSelectedCandidates}} selected</span>
                        <p>Click on the checkbox and select the candidate of your choice. Repeat until at least one vancacie is filled and 
                            the number of candidates does not exceed the number of vancacies. At the end, click on the VOTE button</p>
                        <ng-multiselect-dropdown 
                            [settings]="settings"
                            [data]="pollDataCandidates"
                            [(ngModel)]="selectedItems"            
                            (onSelect)="onItemSelect($event)"
                            (onDeSelect)="onItemDeSelect($event)"
                            (onSelectAll)="onSelectAll($event)"
                            (onDeSelectAll)="onDeSelectAll($event)"
                            formControlName="selectedCandidates">
                        </ng-multiselect-dropdown>
                    </div>
                    <div class="card-footer d-lg-flex justify-content-lg-center">
                        <div class="d-lg-flex justify-content-lg-center align-items-lg-center poll-btn-vote-generic-board-tsp">
                            <button class="btn btn-success btn-block" type="button" id="voteTob" [disabled]="allowVote" >Vote</button>
                        </div>
                    </div>
                </form>
            </div>

            <div class="card card-padding" *ngIf="partner || apartner">
                <p class="text-right float-right poll-details-date">This poll will end on: {{pollData.end_date | dateTimeFormatFilter : "MMM DD, YYYY HH:mm"}}</p>
                <h4>{{pollData.name}}</h4>
                <p class="card-text">{{pollData.description}}</p><br>
                <h5 *ngIf="partner">Vote for the partner candidates below</h5>
                <h5 *ngIf="apartner">Vote for the associate partner candidates below</h5>
                <p>To make your option, click: on YES or NO button for each candidate name and then on Vote button</p>
                <form>
                    <div class="card-columns">
                        <div class="card poll-card-candidate-width" *ngFor="let candidate of pollData.candidates let i = index">
                            <img height="250" width="250" *ngIf="candidate.attachments[0].link != null" class="card-img-top w-100 d-block" src={{photoBytes[i]}}>
                            <img  *ngIf="candidate.attachments[0].link == null" class="card-img-top w-100 d-block img-no-candidate">
                            <div class="card-body">
                                <h4 class="card-title">{{candidate.candidatename}}</h4>
                                <h6 class="card-text">{{candidate.candidateLos}}</h6>
                                <p class="card-text">{{candidate.candidatedesc}}&nbsp;
                                <br><br>
                                <button type="button" *ngIf="candidate.attachments[1].link != ''" class="btn btn-light float-left poll-btn-download-business-case2"
                                    (click)="getDownloadBusinessCase(candidate.attachments[1].name, candidate.attachments[1].link)">
                                    Download Business Case <div class="icon-action icon-download-orange"></div>
                                </button>
                                <button type="button" *ngIf="candidate.attachments[1].link == ''" class="disabled btn btn-light float-left poll-btn-no-business-case">
                                    No Business Case Attached <div class="icon-action icon-download-light-grey"></div>
                                </button>
                                </p>
                                <br><br>
                                <div class="d-lg-flex justify-content-lg-center align-items-lg-center">
                                    <div class="btn-group btn-group-toggle">
                                        <label class="btn disabled btn-success btn-block btn-striped">
                                            <input disabled type="radio" > YES
                                        </label>
                                        <h5 class="d-flex float-none d-lg-flex justify-content-lg-center align-items-lg-center voting-or">&nbsp;or&nbsp;</h5>
                                        <label class="btn disabled btn-danger btn-block btn-striped">
                                            <input disabled type="radio" > NO
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <button disabled class="btn btn-success btn-block btn-striped btn-vote-partner-apartner" role="button">Vote</button>
                </form>
            </div>

        </div>
    </div>
</div>
<br>
<div class="row" >
    <div class="col">
        <form  #f="ngForm">
            <div>
                <button (click)='sendMessage(f, $event)' id="accept" class="btn btn-approve" type="submit">
                    Approve Poll <div class="icon-action icon-checkmark-white"></div>
                </button>
                <button (click)="showDecline()" class="btn btn-decline" *ngIf="!decline">Decline Poll&nbsp;<div class="icon-action icon-close-white"></div></button>
                <a *ngIf="pollData.pollType == 4" class="btn btn-edit" role="button" routerLink="../../edit-poll/generic/{{pollData.id}}">
                    Edit Poll <div class="icon-action icon-edit-white"></div>
                </a>
                <a *ngIf="pollData.pollType == 0 || pollData.pollType == 1" class="btn btn-edit" role="button" routerLink="../../edit-poll/board/{{pollData.id}}">
                    Edit Poll <div class="icon-action icon-edit-white"></div>
                </a>
                <a *ngIf="pollData.pollType == 2 || pollData.pollType == 3" class="btn btn-edit" role="button" routerLink="../../edit-poll/partner/{{pollData.id}}">
                    Edit Poll <div class="icon-action icon-edit-white"></div>
                </a>
            </div>

            <div class="form-group text-decline" *ngIf="decline">
                <textarea  placeholder="Send a comment to the administrator regarding your decision" autofocus="" [(ngModel)]="optionObjct.approver_message"
                    class="form-control" [ngClass]="{ 'is-invalid': f.invalid && f.submitted  }" required></textarea>
                <div *ngIf="f.invalid && f.submitted" class="invalid-feedback">
                    <div>Message is required</div>
                </div>
            </div>
            <button (click)='sendMessage(f, $event)' *ngIf="decline" id="decline" class="btn btn-decline" type="submit">
                Decline Poll&nbsp;<div class="icon-action icon-close-white"></div>
            </button>
        </form>
    </div>
</div>

<!-- Loader/ Alert-->
<div *ngIf="loader" class='overlay'>
    <div class="spinner">
        <div class="spinnerInner">
            <img src="..\assets\img\loader_old.gif">
        </div>
    </div>
</div>
<div *ngIf="error" class='overlay'>
    <div class="alert alert-danger alert-dismissible fade show"><span><i class="icon-action icon-checkmark-white"></i></span>
        We are sorry, an error has occurred. Please contact the system administrators.
        <button type="button" class="close" data-dismiss="alert" aria-label="Close">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
</div>
<div *ngIf="success" class='overlay'>
    <div class="alert alert-success">
        <span><i class="icon-action icon-checkmark-white"></i></span>
        The poll has been saved
    </div>
</div>
