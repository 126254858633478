import { Component, OnInit } from '@angular/core';
import { DataService } from '../data.service';
import { PollService } from '../services/poll.service';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators, FormArray, FormControl, NgForm } from '@angular/forms';
import { AuthService } from '../auth.service';
import { User } from '../interfaces/user';
import swal from 'sweetalert2';
import * as fileSaver from 'file-saver-es';
import { PollType } from '../utils/poll-type.enum';
import { PollSaveType } from '../utils/poll-save-type.enum';

declare var $: any;

@Component({
  selector: 'app-approve-poll',
  templateUrl: './approve-poll.component.html',
  styleUrls: ['./approve-poll.component.scss']
})
export class ApprovePollComponent implements OnInit {

  //#region Variables
  pollData: any = [
    {
      'title': '',
      'subtitle': '',
      'description': '',
      'enddate': '',
      'status': '',
      'pollType': ''
    }
  ];
  optionObjct = {
    'approver_pwc_guid': '',
    'approver_message': '',
    'id': '',
    'saveType': 0,
  };
  generic = false;
  boardTsp = false;
  abc = 0;
  countSelectedCandidatexs = 0;
  tobVotingForm: FormGroup;
  reportVotingForm: FormGroup;
  votesForm: FormGroup;
  user: User;
  partner: boolean;
  confirmation = false;
  success = false;
  saving = false;
  id: string;
  decline = false;
  failed = false;
  loader = false;
  error = false;
  link: any[] = [];
  finantialReportFileName: any[] = [];
  settings = {};
  pollDataCandidates: any;
  countSelectedCandidates = 0;
  selectedItems: any[] = [];
  allowVote = true;
  maxVacancy = 0;
  apartner = false;
  photoBytes: any[] = [];
  value: any;
  approved: PollSaveType = PollSaveType.Approved;
  //#endregion Variables

  constructor(private data: DataService, private pollService: PollService,
    private route: ActivatedRoute, private router: Router, private formBuilder: FormBuilder, private auth: AuthService) {

    this.user = auth.getUserInfo();
  }

  confirmSavedPoll() {
    this.loader = false;
    this.success = true;
    setTimeout(() => {
      this.router.navigate(['/admin']);
    }
      , 2000);
  }

  pollErrorMessage() {
    this.loader = false;
    this.error = true;
    setTimeout(() => {
      this.router.navigate(['/admin']);
    }
      , 2000);
  } 


  //#region Action Buttons
  goBack() {
    window.history.back();
  }

  showDecline() {
    this.decline = true;
  }

  sendMessage(f: NgForm, clicked: Event) {
    let elementId: string = (event.target as Element).id;
    if (elementId === 'accept') {
      this.optionObjct.approver_pwc_guid = this.user.guid;
      this.optionObjct.id = this.pollData.id;
      this.optionObjct.saveType = PollSaveType.Approved;
      this.optionObjct.approver_message = PollSaveType[this.approved];
      if (f.valid) {
        this.loader = true;
        this.data.approvePoll(this.optionObjct).subscribe(
          data => swal.fire({title: 'Success!', text: 'The poll has been saved.', icon: 'success', confirmButtonColor: '#d04a02'})
          .then(() => {
              this.router.navigate(['/admin']);
          }),
          error => {
            swal.fire({title: 'Oops...',
            html: '<p>We are sorry, an error has occurred. Please contact the system administrators.</p>' +
                '<br><div class="alert alert-danger" role="alert"><p> Error: </p><br>' + error.error.message + '<br><br>'
                + error.message + '</div>',
            icon: 'error', confirmButtonColor: '#d04a02'})
            .then(() => {
                // this.router.navigate(['/admin']);
            });
          }
        );
      }
    } else {
    // OBS.: Somente retirar comentario na implantação
    // this.emailContent.to_address = this.voterSelected.email;
    this.optionObjct.approver_pwc_guid = this.user.guid;
    this.optionObjct.id = this.pollData.id;
    this.optionObjct.saveType = PollSaveType.ActionRequired;
    if (f.valid) {
      this.loader = true;
      this.data.approvePoll(this.optionObjct).subscribe(
        data => swal.fire({title: 'Success!', text: 'The poll has been saved.', icon: 'success', confirmButtonColor: '#d04a02'})
        .then(() => {
            this.router.navigate(['/admin']);
        }),
        error => {
          swal.fire({title: 'Oops...',
              html: '<p>We are sorry, an error has occurred. Please contact the system administrators.</p>' +
              '<br><div class="alert alert-danger" role="alert"><p> Error: </p><br>' + error.error.message + '<br><br>'
              + error.message + '</div>',
              icon: 'error', confirmButtonColor: '#d04a02'})
              .then(() => {
                  // this.router.navigate(['/admin']);
              });
        }
      );
    }
  }
  }
  //#endregion Action Buttons


  //#region Candidates
  getDownload(fileName: any[], link: any[]) {
    this.loader = true
    for(let i = 0; i< fileName.length;i++){
      this.loader = true
      this.data.getDownload(fileName[i], link[i]).subscribe((data: any) => {
        let blob:any = new Blob([data], { type: 'text/json; charset=utf-8' });
        const url = window.URL.createObjectURL(blob);
        fileSaver.saveAs(blob, fileName[i])
        if(i == fileName.length - 1) this.loader = false
        },
        error => {
          console.log("erro", error.toString());
          this.loader = false
        });
    }
  }

  getDownloadBusinessCase(fileName: string, link: string) {
    this.loader = true
    this.data.getDownload(fileName, link).subscribe((data: any) => {
        let blob: any = new Blob([data], { type: 'text/json; charset=utf-8' });
        const url = window.URL.createObjectURL(blob);
        fileSaver.saveAs(blob, fileName)
        this.loader = false
    },
        error => {
            console.log("erro", error.toString());
            this.loader = false
        });
}

  getDownloadPhoto(i: number) {
    if (this.pollData.candidates[i].attachments[0].link != null && this.pollData.candidates[i].attachments[0].type == 'photo'){
      this.data.getDownload('photo', this.pollData.candidates[i].attachments[0].link).subscribe((data: any) => {
        let blob:any = new Blob([data], { type: 'image/gif' });
        var reader = new FileReader();
        reader.readAsDataURL(blob);
        reader.onloadend = (event:any) => {
          this.photoBytes[i] = event.target.result;
        }
    });
    }
  }

  onDeSelectAll(items: any) {
    this.countSelectedCandidates = items.length;
  }

  onItemDeSelect(item: any) {
    this.countSelectedCandidates--;
  }

  onItemSelect(item: any) {
    this.countSelectedCandidates++;
  }

  onSelectAll(items: any) {
    this.countSelectedCandidates = items.length;
  }

  selectedCandidates() {
    this.data.getPoll(this.id).subscribe(data => {
      this.pollData = data;
      this.maxVacancy = this.pollData.vacancy;

      this.settings = {
        singleSelection: false,
        itemsShowLimit: 5,
        enableSearchFilter: false,
        selectAllText: 'Select All',
        unSelectAllText: 'UnSelect All',
        classes: "myclass custom-class-example",
        idField: 'id',
        textField: 'candidatename',
        labelKey: 'candidatename',
      };
      this.pollDataCandidates = this.pollData.candidates;
      for ( let i of Object.keys(this.pollData.candidates)) {
        if (this.pollData.candidates[i].attachments.length > 0) {
          if ((this.pollData.candidates[i].attachments[0].link != null) && (this.pollData.candidates[i].attachments[0].link != '')) {
            this.getDownloadPhoto(Number(i));
          }
        }
      }

      if (this.pollData.pollType === PollType.GENERIC) {
        this.generic = true;
      } else if (this.pollData.pollType === PollType.BOARD || this.pollData.pollType === PollType.TSP) {
        this.boardTsp = true;
      } else if(this.pollData.pollType === PollType.PARTNER){
        this.partner = true;
      } else {
        this.apartner = true;
      }

      if (this.pollData.pollType === PollType.GENERIC) {
        this.data.getPoll(this.id).subscribe(data => {
          this.pollData = data;
          //TOSEE
          for(let i = 0; i< this.pollData.attachments.length;i++){
            this.link[i] = this.pollData.attachments[i].link
            this.finantialReportFileName[i] = this.pollData.attachments[i].name;
            console.log(this.finantialReportFileName[i])
          }
        })
      }
    },
      error => {
        console.log(error);
    });
  }
  //#endregion Candidates


  //#region Load
  public getDetail() {
    this.id = this.route.snapshot.paramMap.get('id');
    this.selectedCandidates();    
  }
  //#endregion Load


  //#region OnInit
  ngOnInit() {
    this.getDetail();
    this.formFields();    
    const component = this;
    $(document).ready( ()=> {
      // make code pretty
      // hack for iPhone 7.0.3 multiselects bug
      if (navigator.userAgent.match(/iPhone/i)) {
        $('select[multiple]').each( ()=> {
          const select = $(this).on({
            'focusout': function () {
              const values = select.val() || [];
              setTimeout(function () {
                select.val(values.length ? values : ['']).change();
              }, 1000);
            }
          });
          let firstOption = '<option value="" disabled="disabled"';
          firstOption += (select.val() || []).length > 0 ? '' : ' selected="selected"';
          firstOption += '>Select ' + (select.attr('title') || 'Options') + '';
          firstOption += '</option>';
          select.prepend(firstOption);
        });
      }
      // DUAL LIST TOB SELECT CANDIDATES
      $('#multiselectTob').multiselect({});
      $('.countSelectedCandidates').html('You have selected ' + 0 + ' candidates');
      $('#multiselectTob_rightSelected').on('click', (e: any, opt: any) => {
        let x = $('#multiselectTob_to option').length;
        let v = component.pollData.vacancy;
        if (x === v) {
          $('#multiselectTob_rightSelected').attr('disabled', 'disabled');
          $('#voteTob').removeAttr('disabled');
        }
        $('.countSelectedCandidates').html('You have selected ' + x + ' of ' + v);
      });
      $('#multiselectTob_leftSelected').on('click', (e: any, opt: any) => {
        let x = $('#multiselectTob_to option').length;
        let v = component.pollData.vacancy;
        if (x < v) {
          $('#multiselectTob_rightSelected').removeAttr('disabled');
          $('#voteTob').attr('disabled', 'disabled');
        }
        $('.countSelectedCandidates').html('You have selected ' + x + ' of ' + v);
      });
      let countries = $('#multiselectTob_to').find('option').map( ()=> { return this.value; }).get().join(", ");
      // console.log(countries);
    });
  }
  //#endregion OnInit

  //#region FormFields
  formFields(){
    this.tobVotingForm = this.formBuilder.group({
      candidates: ['', Validators.required],
      poll_id: ['', Validators.required],
      answer: ['Y'],
      voter: ['', Validators.required]
    });

    this.reportVotingForm = this.formBuilder.group({
      answer: ['', Validators.required],
      poll_id: ['', Validators.required],
      candidate: ['', Validators.required],
      voter: ['', Validators.required]
    });

    this.votesForm = this.formBuilder.group({
      votesForm: [[], Validators.required]
    });
  }

  //#endregion FormFields
}
