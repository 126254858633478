import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DataService } from '../data.service';
import { AuthService } from '../auth.service';
import { User } from '../interfaces/user';
import { NgForm } from '@angular/forms';
import swal from 'sweetalert2';
import { PollSaveType } from '../utils/poll-save-type.enum';

interface Result {
  candidate_id: number;
  result: string;
}

@Component({
  selector: 'app-result-poll',
  templateUrl: './result-poll.component.html',
  styleUrls: ['./result-poll.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ResultPollComponent implements OnInit {
  //#region Variables
  public poll_detail: any = {
    'name': '',
    'saveType': '',
    'id': '',
    'description': '',
    'created_by': '',
    'subtitle': '',
    'title': '',
    'created_date': '',
    'begin_date': '',
    'end_date': '',
  };
  copyPoll_detail: any[] = [];
  optionObjct = {
    'lawyer': '',
    'poll_id': '',
    'saveType': 0,
    'lawyer_message': '',
    'results': [] as Result[]
  };
  candidate_detail: any;
  pollArray = [0];
  candidates!: Object;
  votersSelected: [] = [];
  success: Boolean = false;
  failed: Boolean = false;
  user!: User;
  loader = false;
  error = false;
  totalVoters = 0;
  partialTurnout = 0;
  voterTurnout = 0;
  //#endregion Variables

  constructor(private data: DataService, private auth: AuthService, private route: ActivatedRoute, private router: Router) { }

  auditPoll(f: NgForm) {
    this.optionObjct.lawyer = this.user.guid;
    this.optionObjct.poll_id = this.poll_detail.id;
    this.optionObjct.saveType = PollSaveType.Completed;
    this.optionObjct.lawyer_message = f.value.lawyer_message;

    // Adicionando logs de depuração para verificar os dados dos candidatos
    console.log("Poll Detail Candidates:", this.poll_detail.candidates);

    this.optionObjct.results = this.poll_detail.candidates.map((candidate: any) => {
      return {
        candidate_id: candidate.id,
        result: `YES= ${candidate.vote_yes}, NO= ${candidate.vote_no}`
      };
    });

    // Adicionando logs de depuração para verificar os resultados antes de enviar
    console.log("Mapped Results:", this.optionObjct.results);

    const allResultsValid = this.optionObjct.results.every(result => result.candidate_id && result.result);

    if (f.valid && allResultsValid) {
      this.loader = true;
      this.data.auditPoll(this.optionObjct).subscribe(
        data => {
          swal.fire({
            title: 'Success!',
            text: 'The poll has been saved.',
            icon: 'success',
            confirmButtonColor: '#d04a02'
          }).then(() => {
            this.router.navigate(['/results']);
          });
        },
        error => {
          swal.fire({
            title: 'Oops...',
            html: '<p>We are sorry, an error has occurred. Please contact the system administrators.</p>' +
              '<br><div class="alert alert-danger" role="alert"><p> Error: </p><br>' + error.error.message + '<br><br>'
              + error.message + '</div>',
            icon: 'error',
            confirmButtonColor: '#d04a02'
          }).then(() => {
            // this.router.navigate(['/admin']);
          });
        }
      );
    } else {
      console.log("Invalid results:", this.optionObjct.results);
      swal.fire({
        title: 'Error!',
        text: 'Each result must include candidate_id and result.',
        icon: 'error',
        confirmButtonColor: '#d04a02'
      });
    }
  } 

  confirmSavedPoll() {
    this.loader = false;
    this.success = true;
    setTimeout(() => {
      this.router.navigate(['/results']);
    }, 2000);
  }

  goBack() {
    window.history.back();
  }

  pollErrorMessage() {
    this.loader = false;
    this.error = true;
    setTimeout(() => {
      this.router.navigate(['/results']);
    }, 2000);
  }

  //#region Load
  public getDetail() {
    const id = this.route.snapshot.paramMap.get('id');

    this.data.getPollResults(id).subscribe(data => {        
      this.poll_detail = data;
      this.pollArray[0] = this.poll_detail;
      this.totalVoters = this.poll_detail.voters.length;

      for (let i of Object.keys(this.poll_detail.voters)) {
        if (this.poll_detail.voters[i].voted === true) 
          this.partialTurnout++;            
      }

      this.voterTurnout = (this.partialTurnout / this.totalVoters) * 100;

      // Adicionando logs de depuração para verificar os dados após carregá-los
      console.log("Poll Detail after getDetail:", this.poll_detail);
    },
    error => {
      console.log(error);
    },
      () => this.copyPoll_detail = this.poll_detail
    );
  }
  //#endregion Load

  //#region OnInit
  public ngOnInit() {
    this.user = this.auth.getUserInfo();
    this.getDetail();
    this.optionObjct = {
      'lawyer': '',
      'poll_id': '',
      'saveType': 0,
      'lawyer_message': '',
      'results': [] as Result[]
    };
  }
  //#endregion OnInit
}
