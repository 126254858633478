import { Component, OnInit } from '@angular/core';    
import swal from 'sweetalert2';    
import { PersonService } from '../services/person.service';    
import { GroupService } from '../services/group.service';    
    
@Component({    
  selector: 'app-person-list',    
  templateUrl: './person-list.component.html',    
  styleUrls: ['./person-list.component.scss']    
})    
export class PersonListComponent implements OnInit {    
    
  //#region Variables    
  itens: any;    
  itensGroup: any;    
  //#endregion Variables    
    
  constructor(private personService: PersonService, private groupService: GroupService) { }    
    
  //#region Button Actions    
  public upload() {    
    swal.fire({    
      title: 'Upload',    
      showCancelButton: true,    
      input: 'file',    
      inputAttributes: {    
        'accept': 'text/csv'    
      },    
      html: 'Please select a CSV file with semicolon separator (;) and the following columns: ' +    
        'pwc_guid; pwc_number; name; email; group_id(This information can be found in edit group) <br><br> <p>Follow this example: </p><img src="assets/img/uploadExample.png">',    
      confirmButtonColor: '#d04a02'    
    }).then(result => {    
    
      if (result.value) {    
        const file: File = result.value;    
        const formData = new FormData();    
        formData.append('file', file);    
        this.personService.upload(file).subscribe(    
          result => {    
            this.ngOnInit();    
            swal.fire({  
              title: 'Success',  
              text: 'File imported successfully. Records processed: ' + result.records_processed,  
              icon: 'success',  
              confirmButtonColor: '#d04a02' 
            });  
          },    
          error => {    
            swal.fire({    
              title: 'Oops...',    
              html: '<p>We are sorry, an error has occurred. Please contact the system administrators.</p>' +    
                '<br><div class="alert alert-danger" role="alert"><p>Error: </p><br>' + error.error.message + '<br><br>' +    
                error.message + '</div>',    
              icon: 'error',    
              confirmButtonColor: '#d04a02'    
            });    
          }    
        );    
      }    
    });    
  }    
  //#endregion Button Actions    
    
  //#region Load    
  async getData() {    
    await this.personService    
      .getAll()    
      .subscribe(data => {    
        this.itens = data;    
      });    
  }    
    
  async getDataGroup() {    
    await this.groupService    
      .getAll()    
      .subscribe(data => {    
        this.itensGroup = data;    
      });    
  }    
  //#endregion Load    
    
  //#region OnInit    
  ngOnInit() {    
    this.getData();    
    this.getDataGroup();    
  }    
  //#endregion OnInit    
}    
