<h2>Partner settings</h2>

<div class="row form-group">
    <div class="col-lg-1">
        <label for="id">Id</label>
        <span class="form-control" id="id">{{item.id}}</span>
    </div>
    <div class="col-lg-3">
        <label for="guid">Pwc GUID</label>
        <input [disabled]="item.id" type="text" class="form-control" id="guid" [(ngModel)]="item.pwc_guid" 
            [ngClass]="{'is-invalid': isFieldInvalid('pwc_guid')}" maxlength="64"/>
        <div *ngIf="invalidFields.pwc_guid" class="invalid-feedback">
            <div>Pwc Guid is required</div>
        </div>
    </div>

    <div class="col-lg-4">
        <label for="profile">Profile</label>
        <select class="form-control" id="profile" [(ngModel)]="item.profile" [ngClass]="{'is-invalid': isFieldInvalid('profile')}">
            <option *ngFor="let c of profiles" [value]="c.id">
                {{ c.value }}
            </option>
        </select>
        <div *ngIf="invalidFields.profile" class="invalid-feedback">
            <div>Profile is required</div>
        </div>
    </div>
    
    <div class="col-lg-1">
        <label for="active">Enabled?</label>
        <input type="checkbox" checked="checked" class="enabled-field" id="active" [(ngModel)]="item.active" />
    </div>
</div>

<div class="row form-group">
    <div class="col-lg-7">
        <label for="name">Name</label>
        <input type="text" class="form-control" id="name" [(ngModel)]="item.name" 
            [ngClass]="{'is-invalid': isFieldInvalid('name')}" maxlength="128"/>
        <div *ngIf="invalidFields.name" class="invalid-feedback">
            <div>Name is required</div>
        </div>
    </div>
    
    <div class="col-lg-5">
        <label for="email">Email</label>
        <input type="email" class="form-control" id="email" [(ngModel)]="item.email" 
            [ngClass]="{'is-invalid': isFieldInvalid('email')}" maxlength="128"/>
        <div *ngIf="invalidFields.email" class="invalid-feedback">
            <div>Email is required</div>
        </div>
    </div>    
</div>

<div class="row form-group">
    <div class="col-lg-4">
        <label for="groupname">Group</label>
        <select class="form-control" id="group" [(ngModel)]="item.group_id" [ngClass]="{'is-invalid': isFieldInvalid('group_id')}">
            <option *ngFor="let c of groups" [value]="c.id">
                {{ c.groupname }}
            </option>
        </select>
        <div *ngIf="invalidFields.group_id" class="invalid-feedback">
            <div>Group is required</div>
        </div>
    </div>    
</div>
<br/>

<div class="row form-group">
    <div class="col-lg-2">
        <a class="btn btn-block btn-secondary btn-cancel" (click)="cancel()">Cancel</a>
    </div>
    <div class="col-lg-2">
        <button class="btn btn-block btn-save" (click)="saveOrUpdate()" type="submit">Save</button>
    </div>
</div>


<!-- Loader/ Alert-->
<div *ngIf="loader" class='overlay'>
    <div class="spinner">
        <div class="spinnerInner">
            <img src="..\assets\img\loader_old.gif">
        </div>
    </div>
</div>
