<!-- Poll Card -->
<h3 class="title">Workspace</h3>
<div class="card-group cards">
    <div class="card card-poll">
        <div class="card-body">
            <h4 class="card-title count-card count-card__poll">{{countPolls$}}</h4>
            <h4 class="card-title text-card text-card__poll">Polls</h4>
        </div>
    </div>
    <div class="card badge-draft">
        <div class="card-body">
            <h4 class="card-title count-card">{{countDraft}}</h4>
            <h4 class="card-title text-card">Drafts</h4>
        </div>
    </div>
    <div class="card badge-pending">
        <div class="card-body">
            <h4 class="card-title count-card">{{countPending}}</h4>
            <h4 class="card-title text-card">Pending Approval</h4>
        </div>
    </div>
    <div class="card badge-action">
        <div class="card-body">
            <h4 class="card-title count-card">{{countAction}}</h4>
            <h4 class="card-title text-card">Action Required</h4>
        </div>
    </div>
    <div class="card badge-approved">
        <div class="card-body">
            <h4 class="card-title count-card">{{countApproved}}</h4>
            <h4 class="card-title text-card">Approved</h4>
        </div>
    </div>
    <div class="card badge-active">
        <div class="card-body">
            <h4 class="card-title count-card">{{countActive}}</h4>
            <h4 class="card-title text-card">Active</h4>
        </div>
    </div>
    <div class="card badge-closed">
        <div class="card-body">
            <h4 class="card-title count-card">{{countClosed}}</h4>
            <h4 class="card-title text-card">Closed</h4>
        </div>
    </div>
    <div class="card badge-accounted">
        <div class="card-body">
            <h4 class="card-title count-card">{{countAccounted}}</h4>
            <h4 class="card-title text-card">Accounted</h4>
        </div>
    </div>
</div>

<!-- Poll List -->
<div *ngIf="polls$" class="card">
    <div class="card-body">
        <h5 class="card-title poll-content-list">Poll summary</h5>
        <div class="table-responsive">
            <table datatable class="table  table-bordered table-hover">
                <thead>
                    <tr>
                        <th class="table-head-type">Type</th>
                        <th class="dashboard-table-head-title">Title</th>
                        <th class="table-head-date">Start Date</th>
                        <th class="table-head-date">End Date</th>
                        <th class="table-head-status">Status</th>
                        <th class="dashboard-table-head-action">Actions</th>
                    </tr>
                </thead>
                <tbody class="text-list">
                    <tr *ngFor="let poll of polls$ ">
                        <td><span class="badge badge-dark">
                                {{poll.pollType | PollTypePipe}}</span></td>
                        <td>{{poll.name}}</td>
                        <td>{{poll.begin_date | dateTimeFormatFilter : "MMM DD, YYYY HH:mm"}}</td>
                        <td>{{ poll.end_date | dateTimeFormatFilter : "MMM DD, YYYY HH:mm"}}</td>
                        <td><span class="badge" [ngClass]="{
                                'badge-draft': poll.saveType==0,
                                'badge-pending': poll.saveType==1,
                                'badge-approved': poll.saveType==2,
                                'badge-action': poll.saveType==3,
                                'badge-active': poll.saveType==4,
                                'badge-closed': poll.saveType==5,
                                'badge-accounted': poll.saveType==6}">
                                {{poll.saveType | PollstatusPipe}}</span></td>
                        <td>
                            <div role="group" class="btn-group">
                                <a class="btn-action btn  btn-sm" *ngIf="poll.saveType==1 && user.role==0 || poll.saveType==1 && user.role === 4" role="button" 
                                    [ngClass]="{ 'disabled': poll.saveType!=1}" routerLink="../approve-poll/{{poll.id}}" placement="top" ngbTooltip="Approve poll">
                                    <div class="icon-action icon-checkmark-grey"></div>
                                </a>
                                <a class="btn-action btn  btn-sm" role="button" routerLink="../poll-details/{{poll.id}}" placement="top" ngbTooltip="See details">
                                    <div class="icon-action icon-see-grey"></div>
                                </a>
                                <a *ngIf="poll.pollType == 4" class="btn-action btn  btn-sm" role="button" routerLink="../edit-poll/generic/{{poll.id}}" 
                                    [ngClass]="{ 'disabled': poll.saveType!=0 && poll.saveType!=3 && poll.saveType!=1 }"  placement="top" ngbTooltip="Edit draft">
                                    <div class="icon-action icon-edit-grey"></div>
                                </a>
                                <a *ngIf="poll.pollType == 0 || poll.pollType == 1" class="btn-action btn  btn-sm"  role="button" routerLink="../edit-poll/board/{{poll.id}}" 
                                    [ngClass]="{ 'disabled': poll.saveType!=0 && poll.saveType!=3 && poll.saveType!=1 }" placement="top" ngbTooltip="Edit draft">
                                    <div class="icon-action icon-edit-grey"></div>
                                </a>
                                <a *ngIf="poll.pollType == 2 || poll.pollType == 3" class="btn-action btn  btn-sm" role="button" routerLink="../edit-poll/partner/{{poll.id}}" 
                                    [ngClass]="{ 'disabled': poll.saveType!=0 && poll.saveType!=3 && poll.saveType!=1 }" placement="top" ngbTooltip="Edit draft">
                                    <div class="icon-action icon-edit-grey"></div>
                                </a>
                                <a class="btn-action btn  btn-sm" *ngIf="poll.saveType < 4" role="button" (click)="deletePoll(poll.id)" placement="top" ngbTooltip="Delete poll">
                                    <div class="icon-action icon-trash-grey"></div>
                                </a>
                                <a class="btn-action btn  btn-sm" *ngIf="poll.saveType==4" role="button" (click)="cancelPoll(poll.id)" placement="top" ngbTooltip="Cancel poll">
                                    <div class="icon-action icon-close-grey"></div>
                                </a>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
    <div class="card-footer">
    </div>
</div>

<!-- Loader/ Alert-->
<div *ngIf="loader" class='overlay'>
    <div class="spinner">
        <div class="spinnerInner">
            <img src="..\assets\img\loader_old.gif">
        </div>
    </div>
</div>
<div *ngIf="error" class='overlay'>
    <div class="alert alert-danger alert-dismissible fade show"><span><i class="icon-action icon-close-white"></i></span>
        We are sorry, an error has occurred. Please contact the system administrators.
        <button type="button" class="close" data-dismiss="alert" aria-label="Close">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
</div>
<div *ngIf="success" class='overlay'>
    <div class="alert alert-success"><span><i class="icon-action icon-checkmark-white"></i></span>
        The poll has been {{saveAction}}</div>
</div>